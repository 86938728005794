import { createTheme } from '@mui/material/styles';
import { Colors, setCommon } from './Common';

export let Light = setCommon(Colors.textPrimary, {
    spacing: 16,
    typography: {
        body1: {
            fontWeight: 400,
            fontSize: '1rem'
        },
        h1: { // Title
            fontWeight: 700,
            fontSize: '2rem',
            color: Colors.textPrimary
        },
        h2: {
            fontWeight: 600,
            fontSize: '1.5rem',
            color: Colors.actionBlue

        },
        h3: { // Also Body Bold / Button Labels
            fontWeight: 600,
            fontSize: '1.125rem',
            color: Colors.actionBlue,
        },
        h4: { // sub-text
            fontWeight: 600,
            fontSize: '1rem',
            color: Colors.textPrimary
        },
        h5: { // sub-text
            fontWeight: 400,
            fontSize: '0.875rem',
            color: Colors.textSecondary
        },
        h6: { //Dollars
            color: Colors.pilotGreen,
            fontWeight: 400,
            fontSize: '1.5rem',
            '@media (min-width:600px)': {
                fontSize: '1rem',
              },
        },
        caption: {
            fontWeight: 500,
            fontSize: '1rem',
            color: Colors.textSecondary,
        },
    },
    palette: {
        mode: "light",
        primary: {
            main: Colors.blue2,
            dark: Colors.blue3,
            light: Colors.blue1,
            contrastText: Colors.white
        },
        background: {
            paper: Colors.white,
            default: Colors.white
        },
        action: {
            disabled: Colors.gray3
        },
        divider: Colors.actionBlue
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(12, 31, 43, 0.72)"
                }
            }
        }
    },
    maxHeaderWidth: '74rem'
});

Light = createTheme(Light, {
    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: Light.palette.primary.dark,
                },
                outlinedPrimary: {
                    "&:active": {
                        backgroundColor: Light.palette.primary.light,
                        borderColor: Light.palette.primary.dark,
                        color: Light.palette.primary.dark
                    }
                },
                textPrimary: {
                    color: Light.palette.primary.dark,
                    "&:active": {
                        backgroundColor: Light.palette.primary.light,
                        color: Light.palette.primary.dark
                    }
                }
            }
        },
    }
});
