import * as React from 'react';
import { UserContext } from '../contexts';
import { SimSteps } from '../models';
import { StepWizardPageProps } from './BasePage';
import { VehicleInfoIpsosPage } from './VehicleInfoIpsosPage';
import { VehicleInfoVinPage } from './VehicleInfoVinPage';

export const VehicleInfoPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    
    return !!user?.ipsos_id ? (<VehicleInfoIpsosPage {...props}/>) : (<VehicleInfoVinPage {...props}/>);
}

VehicleInfoPage.defaultProps = {
    stepName: SimSteps.VehicleInfo.name
};
