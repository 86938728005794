import { Box, Button, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _ from "lodash";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { ShowLearnMoreContext, UserContext } from "../contexts";
import { ReportingOptions, SimSteps } from "../models";
import BaseInputPage from "./BaseInputPage";
import { StepWizardPageProps } from "./BasePage";
import { Colors } from "../themes/Common";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { ErrorIcon } from "../icons";

const boxWidth = "16.125rem";

const PickBoxStyles = {
	padding: "1.1875rem",
};

const PickBoxTitleStyles = {
	paddingBottom: "0.75rem",
	color: Colors.textPrimary,
};

const PickBoxSubHeaderStyles = {
	fontSize: "1rem",
	color: Colors.textPrimary,
	align: "start",
};

const PickBoxBodyStyles = {
	fontSize: "0.875rem",
	borderBottom: "1px solid " + Colors.actionBlue,
	padding: "0.56rem 0.75rem",
	color: Colors.textPrimary,
	align: "start",
};

const ToggleBoxStyles = {
	padding: "0",
	alignItems: "start",
};

const options = {
	Manual: {
		value: ReportingOptions.Manual.value,
		header: ReportingOptions.Manual.text,
		body: "Enter your odometer reading online quarterly.\n",
		price: "Administrative costs ($)",
		restrictedTo: "",
		features: ["Online service", "Requires internet", "No miles exempt", "No GPS"],
	},
	Photo: {
		value: ReportingOptions.Photo.value,
		header: ReportingOptions.Photo.text,
		body: "Submit a photo of your odometer on an app monthly.",
		price: "Administrative costs ($$)",
		restrictedTo: "",
		features: ["Services by GeoToll*", "Requires smartphone", "No miles exempt", "No GPS"],
	},
	Smartapp: {
		value: ReportingOptions.Smartapp.value,
		header: ReportingOptions.Smartapp.text,
		body: "Automatically record mileage using location (vehicle models 2015 and newer).\n",
		price: "Administrative costs ($$)",
		restrictedTo: "smart_app",
		features: ["Services by GeoToll*", "Requires vehicle Bluetooth", "Miles can be exempt", "GPS Enabled"],
	},
	Telematics: {
		value: ReportingOptions.Telematics.value,
		header: ReportingOptions.Telematics.text,
		body: "Automatically share mileage through vehicle (some vehicle models 2022 and newer).\n",
		price: "Administrative costs ($$$)",
		restrictedTo: "telematics",
		features: ["Services by GeoToll*", "Requires vehicle telematics", "Miles can be exempt", "GPS Enabled"],
	},
};

export const ReportingOptionsPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user } = React.useContext(UserContext);
	const [mro_selection, setSelected] = React.useState("");
	const [veh_mi, setVeh_mi] = useState<number | "">("");
	const [showError, setShowError] = useState(false);
	const { setShowLearnMore } = React.useContext(ShowLearnMoreContext);

	React.useEffect(() => {
		setSelected(user?.mro_selection || "");
		setVeh_mi(user?.veh_mi || "");
	}, [user, user?.mro_selection, user?.veh_mi]);

	const handleSelect = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
		setSelected(value);
	};

	const veh_miChanged = (newValue: string) => {
		const entry = parseInt(newValue);
		setVeh_mi(entry);
	};
	const checkExtremeMileage = (e: React.FormEvent<HTMLInputElement>) => {
		const val = (e.target as HTMLInputElement).value;
		if (val.replace(/,/g, "").length >= 7) {
			setShowError(true);
		} else {
			setShowError(false);
		}
	};

	const confirmOdo = () => {
		if (!!veh_mi && !showError) {
			props.nextPage();
		}
	};

	return (
		<BaseInputPage
			stepWizardProps={props as unknown as StepWizardChildProps}
			canNavigateForward={mro_selection !== ""}
			userUpdates={{
				mro_selection: mro_selection,
				veh_mi: veh_mi || undefined,
			}}
			acceptOnClick={async () => {
				if (mro_selection === ReportingOptions.Manual.value) {
					setShowLearnMore(true);
					return false;
				}
				return true;
			}}
			showTotals={true}
			title="How would you like to report your miles?"
			learnMoreContent={
				<Grid container spacing={1}>
					<Grid xs={12}>
						<Typography variant="h2" sx={{ color: Colors.textPrimary }}>
							Enter your odometer mileage
						</Typography>
					</Grid>
					<Grid xs={12}>
						<Typography>
							Enter your vehicle's current odometer mileage below. We'll use this to calculate your total
							miles driven during the pilot period.
						</Typography>
					</Grid>
					<Grid xs={12} sx={{ marginTop: 1 }}>
						<Typography variant="caption">Current odometer mileage</Typography>
						<br />
						<NumberFormat
							sx={{ marginTop: 0.75, marginBottom: 1, width: "16.125rem" }}
							thousandSeparator=","
							customInput={TextField}
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								checkExtremeMileage(e);
							}}
							onValueChange={(values) => veh_miChanged(values.value)}
							value={veh_mi}
						/>
						<br />
						<div style={{ alignItems: "center", display: showError ? "flex" : "none" }}>
							<ErrorIcon />
							<Typography sx={{ color: Colors.red, marginLeft: "0.5rem" }}>
								Please enter an odometer reading less than 1,000,000
							</Typography>
						</div>
					</Grid>
					<Grid xs={12}>
						<Button
							sx={{ float: "left", marginBottom: 2, width: "3.25rem" }}
							variant="contained"
							onClick={confirmOdo}
							disabled={!veh_mi || showError}
						>
							Confirm
						</Button>
					</Grid>
					<Grid xs={12} sx={{ marginTop: 2 }}>
						<Typography variant="h4" sx={{ color: Colors.textSecondary }}>
							How to find your odometer mileage
						</Typography>
					</Grid>
					<Grid xs={12}>
						<Typography sx={{ color: Colors.textSecondary }}>
							An odometer is used to display the distance traveled by your vehicle. To read your odometer,
							look on your vehicle's dashboard for a small rectangle containing numbers. In newer
							vehicles, the odometer is typically electronic. In older vehicles, the odometer may be
							mechanical.
						</Typography>
					</Grid>
					<Grid xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
						<img style={{ maxWidth: "16.125rem" }} src="/images/odometer1.png" alt="Odometer" />
					</Grid>
					<Grid xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
						<img style={{ maxWidth: "16.126rem" }} src="/images/odometer2.png" alt="Odometer" />
					</Grid>
					<Grid xs={12}>
						<Typography sx={{ color: Colors.textSecondary }}>
							Beware that many cars often contain secondary odometers, usually labeled Trip, that you can
							reset to zero to measure short trip distances. Electronic odometers include a toggle to
							switch between the odometer and trip odometers. Mechanical odometers often include both the
							trip odometer and the odometer stacked on top of one another. The odometer is the larger
							number. When reporting your mileage, be careful to look at the odometer and not the trip
							odometer. If you have questions about the odometer for your vehicle, consult your vehicle
							owner's manual for assistance.
						</Typography>
					</Grid>
				</Grid>
			}
		>
			<Typography sx={{ marginTop: "4rem" }}>Mileage Reporting Method</Typography>
			<Typography sx={{ margin: "1rem" }} variant="h5">
				The methods that are compatible with your vehicle are available for selection.
			</Typography>
			<Box sx={{ display: "flex", marginBottom: "4rem" }}>
				<ToggleButtonGroup
					value={mro_selection}
					exclusive
					onChange={handleSelect}
					sx={{
						display: "flex",
						flexWrap: "wrap",
						gridGap: "1rem",
						alignItems: "start",
						marginTop: "1rem",
						marginBottom: "1.25rem",
						justifyContent: "center",
					}}
				>
					{_.map(options, (item) => (
						<ToggleButton
							value={item.value}
							selected={mro_selection === item.value}
							style={ToggleBoxStyles}
							sx={{ textAlign: "start" }}
							disabled={!!item.restrictedTo && !!user && !(user as any)[item.restrictedTo]}
						>
							<Box
								width={boxWidth}
								margin={mro_selection === item.value ? "-2px" : "0px"}
								sx={{ height: "100%", display: "flex", flexDirection: "column" }}
							>
								<Box
									style={PickBoxStyles}
									sx={{
										height: "10rem",
										borderBottom: "1px solid " + Colors.actionBlue,
										borderWidth: mro_selection === item.value ? "3px" : "1px",
										marginBottom: mro_selection === item.value ? "-1px" : "0px",
									}}
								>
									<Typography variant="h2" style={PickBoxTitleStyles} sx={{ alignText: "start" }}>
										{item.header}
									</Typography>
									<Typography
										style={PickBoxSubHeaderStyles}
										sx={{ marginBottom: mro_selection === item.value ? "-1px" : "0px" }}
									>
										{item.body}
									</Typography>
								</Box>
								<Box>
									<Box style={PickBoxStyles} sx={{ paddingTop: "0 !important" }}>
										{_.map(item.features, (feature, index) =>
											user?.state !== "MN" && index === item.features.length - 1 ? (
												<Typography
													style={PickBoxBodyStyles}
													sx={{ borderBottom: "none !important" }}
												>
													{feature}
												</Typography>
											) : (
												<Typography style={PickBoxBodyStyles}>
													{index === 2 ? (
														feature === "No miles exempt" ? (
															<span style={{ color: Colors.red, marginRight: ".5em" }}>
																✘
															</span>
														) : (
															<span
																style={{
																	color: Colors.pilotGreen,
																	marginRight: ".5em",
																}}
															>
																✔
															</span>
														)
													) : (
														<></>
													)}
													{feature}
												</Typography>
											)
										)}
										{user?.state === "MN" && (
											<Typography
												style={PickBoxBodyStyles}
												sx={{ borderBottom: "none !important" }}
											>
												{item.price}
											</Typography>
										)}
									</Box>
								</Box>
							</Box>
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<Typography sx={{ margin: "-4rem auto 2rem 1rem", fontStyle: "italic" }} variant="h5">
				*GeoToll is a private firm that provides mileage collection technology for this pilot.
			</Typography>
		</BaseInputPage>
	);
};

ReportingOptionsPage.defaultProps = {
	stepName: SimSteps.ReportingOptions.name,
};
