import { Box, Grid, Link } from "@mui/material";
import * as React from "react";
import { Colors } from "../../themes/Common";
import { ExternalLinkIcon } from "../../icons";
import { UserContext } from "../../contexts";
import { QuestionIcon } from "../../icons/QuestionIcon";
import { CloseIcon } from "../../icons";
import "./Header.css";

export interface HeaderProps {
	useDarkTheme?: boolean;
	showLogo?: boolean;
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
	const { user } = React.useContext(UserContext);

	const rucLogo = props.useDarkTheme ? (
		<img
			src="/images/midwest_ruc_logo_white.png"
			alt="Midwest RUC Logo"
			style={{ transform: "translate(-9px)", height: "64px", paddingRight: "20px" }}
		/>
	) : (
		<img
			src="/images/midwest_ruc_logo.png"
			alt="Midwest RUC Logo"
			style={{ transform: "translate(-9px)", height: "64px", paddingRight: "20px" }}
		/>
	);

	let stateLogo;
	if (user?.state === "KS") {
		stateLogo = props.useDarkTheme ? (
			<img
				src="/images/kansas_pilot_logo_white.png"
				alt="Kansas Pilot Logo"
				style={{
					transform: "translate(-9px)",
					height: "64px",
					paddingLeft: "20px",
					borderLeft: "1px solid white",
				}}
			/>
		) : (
			<img
				src="/images/kansas_pilot_logo.png"
				alt="Kansas Pilot Logo"
				style={{
					transform: "translate(-9px)",
					height: "64px",
					paddingLeft: "20px",
					borderLeft: "1px solid #002856",
				}}
			/>
		);
	} else if (user?.state === "MN") {
		stateLogo = props.useDarkTheme ? (
			<img
				src="/images/minnesota_pilot_logo_white.png"
				alt="Minnesota Pilot Logo"
				style={{
					transform: "translate(-9px)",
					height: "64px",
					paddingLeft: "20px",
					borderLeft: "1px solid white",
				}}
			/>
		) : (
			<img
				src="/images/minnesota_pilot_logo.png"
				alt="Minnesota Pilot Logo"
				style={{
					transform: "translate(-9px)",
					height: "64px",
					paddingLeft: "20px",
					borderLeft: "1px solid #002856",
				}}
			/>
		);
	}

	const handleHelpDesk = () => {
		const modal = document.getElementById("help-desk-modal");
		if (modal) {
			modal.classList.toggle("hidden");
		}
	};

	const helpDeskColor = props.useDarkTheme ? Colors.lightBlue : Colors.textSecondary;
	const mobileHelpDeskColor = props.useDarkTheme ? Colors.lightBlue : Colors.actionBlue;
	const helpDesk = (
		<Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
			<Link
				onClick={handleHelpDesk}
				sx={{
					display: { xs: "none", md: "block" },
					cursor: "pointer",
					textDecoration: "none",
					fontSize: "1.125rem",
					fontWeight: "700",
					color: helpDeskColor,
					marginRight: "6px",
				}}
			>
				Help Desk
			</Link>
			<ExternalLinkIcon color={helpDeskColor} />
		</Box>
	);

	return (
		<>
			<Grid xs={12} sx={{ display: "flex" }} style={{ maxWidth: "74rem" }}>
				<Grid xs={8} md={9}>
					<Box
						marginTop="0.1875rem"
						sx={{ pointerEvents: "none", display: props.showLogo ? "flex" : "none" }}
						className="logos"
					>
						{rucLogo}
						{stateLogo}
					</Box>
				</Grid>
				<Grid xs={4} md={3}>
					<Box marginTop="1.1875rem" sx={{ display: "flex", justifyContent: "flex-end" }}>
						{helpDesk}
						<Box sx={{ display: { xs: "block", md: "none" } }} onClick={handleHelpDesk}>
							<QuestionIcon color={mobileHelpDeskColor} />
						</Box>
					</Box>
				</Grid>
			</Grid>
			<div className={"help-desk-modal hidden"} id={"help-desk-modal"}>
				<div className="modal-content">
					<div className="modal-header">
						{" "}
						<CloseIcon id="close-modal" style={{ cursor: "pointer" }} onClick={handleHelpDesk}></CloseIcon>
					</div>
					<div className="modal-body">
						<h2>Questions?</h2>
						<p>
							Email us at{" "}
							<a href={"mailto:contact@midwestruc.org?subject=RUC Enrollment Assistance Needed"}>
								contact@midwestruc.org
							</a>{" "}
							or call us at <a href="tel:1-866-782-2024">1-866-782-2024</a>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

Header.defaultProps = {
	useDarkTheme: false,
	showLogo: true,
};
