import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { UserContext } from "../contexts";
import { ReportingOptions, SimSteps } from "../models";
import { Colors } from "../themes/Common";
import { BaseInputPage } from "./BaseInputPage";
import { StepWizardPageProps } from "./BasePage";
import { BasicModal } from "../components";
import * as Server from "../server";

const InfoBoxHeader = {
	display: "flex",
	justifyContent: "space-between",
	paddingBottom: "1.25rem",
};

const SummaryBoxStyles = {
	border: "1px solid #C9CED1",
	padding: "1.25rem",
	paddingBottom: "1.5rem",
	height: "100%",
};

const FieldStyles = {
	color: Colors.textPrimary,
	fontSize: "1.25rem",
	fontStyle: "normal",
	fontWeight: "500",
};

export const ReviewPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user } = React.useContext(UserContext);
	const stepWizard = props as unknown as StepWizardChildProps;
	const [showDialog, setShowDialog] = React.useState(false);
	const [showGeoTollError, setShowGeoTollError] = React.useState(false);
	const [error, setError] = React.useState<any>(null);

	const reportingOptions = Object.keys(ReportingOptions).reduce((obj, key) => {
		obj[(ReportingOptions as any)[key].value] = (ReportingOptions as any)[key].text;
		return obj;
	}, {} as any);

	React.useEffect(() => {}, [user]);

	const gotoVehicleInfoPage = () => {
		if (user?.veh_is_hd || user?.ipsos_id) {
			stepWizard.goToNamedStep(SimSteps.VehicleInfo.name);
		} else {
			setShowDialog(true);
		}
	};

	const dialogOnClose = (event: object, reason: string) => {
		if (reason === "accept") {
			stepWizard.goToNamedStep(SimSteps.VehicleInfo.name);
		}

		setShowDialog(false);
	};

	const geoTollErrorOnClose = (event: object, reason: string) => {
		setShowGeoTollError(false);
	};

	const formatNumber = (number: number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const addGeoTollUser = async () => {
		try {
			console.log(user);
			if (user) {
				// const geoTollResponse = await Server.getUserVehicles(user.id);
				// if (geoTollResponse.Status !== 200) {
				// 	//if user is not in geotoll database
				const response = await Server.addGeoTollUser(user.id, user);

				if (response.Status === 200) {
					props.nextPage();
					user.sim_step = SimSteps.Confirmation.stepNumber;
					user.sim_status = SimSteps.Confirmation.name;
					Server.updateUser(user.id, user);
				} else {
					setShowGeoTollError(true);
				}
				// } else {
				// 	props.nextPage();
				// }
			}
		} catch (e) {
			if (user) {
				const message = (e as { message?: string })["message"] ?? "Error adding user to geotoll";
				user.error_message =
					(user.error_message !== undefined ? user.error_message : "") +
					"\n[" +
					new Date().toISOString() +
					"]: " +
					message +
					"\n";

				await Server.getVINSummary(`${user.id}/${user.id}/2024-01-01/2024-12-31`)

					.then((summary) => {
						if (summary.statusCode === 200) {
							props.nextPage();
							user.sim_step = SimSteps.Confirmation.stepNumber;
							user.sim_status = SimSteps.Confirmation.name;
							Server.updateUser(user.id, user);
						} else {
							console.log("Error getting VIN Summary");
							setShowGeoTollError(true);
							setError(e);
						}
					})
					.catch((e) => {
						const message = (e as { message?: string })["message"] ?? "Error adding user to geotoll";
						user.error_message =
							(user.error_message !== undefined ? user.error_message : "") +
							"\n[" +
							new Date().toISOString() +
							"]: " +
							message +
							"\n";
						Server.updateUser(user.id, user);
						setShowGeoTollError(true);
					});
			} else {
				setShowGeoTollError(true);
			}
		}
	};

	return (
		<BaseInputPage
			stepWizardProps={stepWizard}
			userUpdates={{ sim_step: SimSteps.Review.stepNumber }}
			acceptOnClick={async () => {
				if (!user?.ipsos_id && user?.mro_selection !== ReportingOptions.Manual.value) {
					addGeoTollUser();
					return false;
				}
				return true;
			}}
			nextButtonLabel="Confirm"
			showCostEstimate={false}
			showTotals={true}
			title="Review your information"
		>
			<Grid container sx={{ marginTop: "4rem", marginBottom: "4rem" }}>
				<Grid container xs={12} md={9} rowSpacing={"0"} sx={{ marginBottom: "2rem" }}>
					<Grid xs={12}>
						{!!user?.vehcustom && (
							<Box style={SummaryBoxStyles}>
								<Box style={InfoBoxHeader} sx={{ width: "100%" }}>
									<Typography variant="h2" sx={{ color: Colors.textPrimary }}>
										Enrolled Vehicle
									</Typography>
									<Button
										variant="outlined"
										onClick={gotoVehicleInfoPage}
										sx={{ minWidth: "3.0625rem" }}
									>
										Edit
									</Button>
								</Box>
								<Grid container sx={{ width: "100%" }}>
									<Grid
										xs={12}
										md={6}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">Vehicle Info</Typography>
										<Typography style={FieldStyles}>{user?.vehcustom}</Typography>
									</Grid>
									{user.veh_epa_atvtype !== "EV" && (
										<Grid
											xs={12}
											md={6}
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Typography variant="caption">MPG</Typography>
											<Typography style={FieldStyles}>{user?.mpg || "--"}</Typography>
										</Grid>
									)}
								</Grid>
							</Box>
						)}
						{user?.veh_is_hd && (
							<Box style={SummaryBoxStyles}>
								<Box style={InfoBoxHeader} sx={{ width: "100%" }}>
									<Typography variant="h2" sx={{ color: Colors.textPrimary }}>
										Your Vehicle
									</Typography>
									<Button
										variant="outlined"
										onClick={gotoVehicleInfoPage}
										sx={{ minWidth: "3.0625rem" }}
									>
										Edit
									</Button>
								</Box>
								<Grid container rowSpacing={"1.25rem"} sx={{ width: "100%" }}>
									<Grid
										xs={12}
										md={4}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">Gross Vehicle Weight</Typography>
										<Typography style={FieldStyles}>{user?.veh_weight}</Typography>
									</Grid>

									<Grid
										xs={12}
										md={4}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">MPG</Typography>
										<Typography style={FieldStyles}>{user?.mpg}</Typography>
									</Grid>
								</Grid>
							</Box>
						)}
						{!user?.vehcustom && !user?.veh_is_hd && (
							<Box style={SummaryBoxStyles}>
								<Box style={InfoBoxHeader} sx={{ width: "100%" }}>
									<Typography variant="h2" sx={{ color: Colors.textPrimary }}>
										Your Vehicle
									</Typography>
									<Button
										variant="outlined"
										onClick={gotoVehicleInfoPage}
										sx={{ minWidth: "3.0625rem" }}
									>
										Edit
									</Button>
								</Box>
								<Grid container rowSpacing={"1.25rem"} sx={{ width: "100%" }}>
									<Grid
										xs={12}
										md={4}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">Year</Typography>
										<Typography style={FieldStyles}>{user?.vehyear}</Typography>
									</Grid>
									<Grid
										xs={12}
										md={4}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">Make</Typography>
										<Typography style={FieldStyles}>{user?.vehmake}</Typography>
									</Grid>
									<Grid
										xs={12}
										md={4}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">Model</Typography>
										<Typography style={FieldStyles}>{user?.vehmodel}</Typography>
									</Grid>
									{user?.vehtrim && user?.vehtrim !== "!" && (
										<Grid
											xs={12}
											md={4}
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Typography variant="caption">Trim</Typography>
											<Typography style={FieldStyles}>{user?.vehtrim}</Typography>
										</Grid>
									)}
									{user?.veh_epa_atvtype !== "EV" && (
										<Grid
											xs={12}
											md={4}
											sx={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Typography variant="caption">MPG</Typography>
											<Typography style={FieldStyles}>{user?.mpg}</Typography>
										</Grid>
									)}
								</Grid>
							</Box>
						)}
					</Grid>
				</Grid>

				<Grid container xs={12} md={9} columnSpacing={"2rem"}>
					<Grid xs={12} md={6}>
						<Box style={SummaryBoxStyles}>
							<Box style={InfoBoxHeader} sx={{ width: "100%" }}>
								<Typography variant="h2" sx={{ color: Colors.textPrimary }}>
									Estimated Chargeable Miles
								</Typography>
								<Button
									variant="outlined"
									onClick={() => stepWizard.goToNamedStep(SimSteps.MileageReporting.name)}
									sx={{ minWidth: "3.0625rem" }}
								>
									Edit
								</Button>
							</Box>
							<Grid container sx={{ width: "100%" }}>
								<Grid
									xs={12}
									sx={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Typography variant="caption">Total monthly miles</Typography>
									<Typography style={FieldStyles}>
										{user
											?.chargeableMiles()
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
									</Typography>
									{user?.hasAllowedExemption() && (
										<Typography
											sx={{
												fontSize: "0.875rem",
												color: Colors.textPrimary,
											}}
										>
											{formatNumber(user?.mi_est ?? 0)} monthly miles minus{" "}
											{formatNumber(user?.exempt_mi ?? 0)} exempt miles
										</Typography>
									)}
								</Grid>
							</Grid>
						</Box>
					</Grid>

					<Grid xs={12} md={6} marginTop={{ xs: 2, md: 0 }}>
						<Box style={SummaryBoxStyles}>
							<Box style={InfoBoxHeader} sx={{ width: "100%" }}>
								<Typography variant="h2" sx={{ color: Colors.textPrimary }}>
									Mileage Reporting
								</Typography>
								<Button
									variant="outlined"
									onClick={() => stepWizard.goToNamedStep(SimSteps.ReportingOptions.name)}
									sx={{ minWidth: "3.0625rem" }}
								>
									Edit
								</Button>
							</Box>
							<Grid container sx={{ width: "100%" }}>
								<Grid
									xs={12}
									sx={{
										display: "flex",
										flexDirection: "column",
										marginBottom: "1.25rem",
									}}
								>
									<Typography variant="caption">Reporting Method</Typography>
									<Typography style={FieldStyles}>
										{(user && user.mro_selection && reportingOptions[user.mro_selection]) || "--"}
									</Typography>
								</Grid>
								<Grid
									xs={12}
									sx={{
										display: "flex",
										flexDirection: "column",
										marginBottom: "1.25rem",
									}}
								>
									<Typography variant="caption">Statement Frequency</Typography>
									<Typography style={FieldStyles}>
										{user?.mro_selection === ReportingOptions.Manual.value
											? "Quarterly"
											: "Monthly"}
									</Typography>
								</Grid>
								{user?.mro_selection === ReportingOptions.Manual.value && (
									<Grid
										xs={12}
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography variant="caption">Initial Odometer Reading</Typography>
										<Typography style={FieldStyles}>{formatNumber(user?.veh_mi ?? 0)}</Typography>
									</Grid>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<BasicModal
				open={showDialog}
				onClose={dialogOnClose}
				abortLabel="No, Continue"
				acceptLabel="Yes, Go Back"
				title="Are you sure?"
				acceptOnRight={false}
			>
				<Typography variant="body1">
					Editing vehicle information will require you to re-calculate your estimated chargeable miles and
					select a mileage reporting option (MRO) compatible with that vehicle.
				</Typography>
			</BasicModal>
			<BasicModal
				open={showGeoTollError}
				onClose={geoTollErrorOnClose}
				abortLabel="Close"
				acceptLabel="Okay"
				title="Error"
				acceptOnRight={false}
			>
				<Typography variant="body1">
					Your account could not be activated. Please contact the help desk to activate your vehicle.
					{/* <br />
					{error} */}
				</Typography>
			</BasicModal>
		</BaseInputPage>
	);
};

ReviewPage.defaultProps = {
	stepName: SimSteps.Review.name,
};
