import { createSvgIcon } from '@mui/material';

export const CloseIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path d="M4.54346 20.7954L20.5437 4.79541" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        <path d="M20.5432 20.7954L4.54346 4.79541" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>
    ,
    "CloseIcon"
);
