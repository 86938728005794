import { Box, Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import { ShowLearnMoreContext, UserContext } from '../contexts';
import BasePage, { BasePageProps } from './BasePage';
import { TotalsEstimate } from '../components';
import { ReportingOptions } from '../models';

export interface BaseInputPageProps extends BasePageProps {
    title: string;
    learnMoreLink?: string;
}

export const BaseInputPage: React.FunctionComponent<BaseInputPageProps> = (props) => {
    const {setShowLearnMore} = React.useContext(ShowLearnMoreContext);
    const {user} = React.useContext(UserContext);
    const [miles, setMiles] = React.useState(0);
    const [exemptMiles, setExemptMiles] = React.useState(0);
    const [includeExemptMiles, setIncludeExemptMiles] = React.useState(false);

    React.useEffect(() => {
        if (user) {
            const mro = !!props.userUpdates.mro_selection ? props.userUpdates.mro_selection : (user.mro_selection || ReportingOptions.Manual.value);
            setIncludeExemptMiles(mro === ReportingOptions.Smartapp.value || mro === ReportingOptions.Telematics.value);
            setMiles((!!props.userUpdates.mi_est || props.userUpdates.mi_est === 0) ? props.userUpdates.mi_est : (user.mi_est || 0));
            setExemptMiles((!!props.userUpdates.exempt_mi || props.userUpdates.exempt_mi === 0) ? props.userUpdates.exempt_mi : (user.exempt_mi || 0));
        }
    }, [user,
        user?.mi_est,
        user?.exempt_mi,
        user?.mro_selection,
        props.userUpdates.mi_est,
        props.userUpdates.exempt_mi,
        props.userUpdates.mro_selection]);

    return (
        <BasePage {...props}>
            <Box>
            <Grid container md={12} sx={{
                    width: '100%',
                    paddingTop: '4.6825rem'
                    }}>
                <Grid md={8}>
                    <Typography variant="h3" sx={{maxWidth: '32.25rem', marginBottom: '1rem'}}>
                        STEP {props.stepWizardProps.currentStep - 1} OF {props.stepWizardProps.totalSteps-2}
                    </Typography>
                    <Typography variant="h1" sx={{maxWidth: '32.25rem'}}>
                        {props.title}
                    </Typography>
                    {!!props.learnMoreLink && !!props.learnMoreContent &&
                    <Link sx={{cursor: 'pointer'}} onClick={() => setShowLearnMore(true)}>{props.learnMoreLink}</Link>
                    }
                    
                </Grid>
                { props.showTotals &&
                    <Grid md={4} sx={{display:{xs: 'none', md:'flex'}, justifyContent: 'end'}}>
                        <TotalsEstimate miles={miles} exemptMiles={exemptMiles} includeExemptMiles={includeExemptMiles}/>
                    </Grid>
                }
            </Grid>
            </Box>
                <Box>
                    {props.children}
                </Box>
                { props.showTotals && 
                    <Grid md={12} sx={{display:{xs: 'flex', md:'none'}, justifyContent: 'center'}}>
                        <TotalsEstimate miles={miles} exemptMiles={exemptMiles} includeExemptMiles={includeExemptMiles}/>
                    </Grid>
                }
        </BasePage>
    );
}

BaseInputPage.defaultProps = {
    showCostEstimate: true,
    showLogo: true,
    showNavigationButtons: true,
    showProgress: true
}

export default BaseInputPage;
