import { createSvgIcon } from '@mui/material';

export const ExclaimationIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 30 30" fill="none">
        <circle cx="13.5" cy="13.3315" r="12" transform="rotate(-180 13.5 13.3315)" stroke="#95C93D" stroke-width="2" />
        <path d="M14.5431 14.4069C14.5269 14.9717 14.0644 15.421 13.4994 15.421C12.9353 15.421 12.4732 14.973 12.4557 14.4092L12.2287 7.07963C12.2066 6.36701 12.7783 5.77734 13.4913 5.77734C14.2031 5.77734 14.7744 6.36527 14.7539 7.07684L14.5431 14.4069ZM13.4898 17.5546C13.9592 17.5546 14.3432 17.7111 14.6419 18.024C14.9549 18.3369 15.1113 18.7138 15.1113 19.1548C15.1113 19.6099 14.9549 19.994 14.6419 20.3069C14.3432 20.6198 13.9592 20.7763 13.4898 20.7763C13.0347 20.7763 12.6506 20.6198 12.3377 20.3069C12.039 19.994 11.8896 19.6099 11.8896 19.1548C11.8896 18.7138 12.0461 18.3369 12.359 18.024C12.672 17.7111 13.0489 17.5546 13.4898 17.5546Z" fill="#95C93D" />
    </svg>
    ,
    "ExclaimationIcon"

    

);
