import * as React from 'react';
import { IPageState } from '../models';
import { PageStateContext } from '../contexts';
import { StepWizardChildProps } from 'react-step-wizard';

export interface PageStateProxyProps extends IPageState {
    stepWizardProps: StepWizardChildProps;
}

export const PageStateProxy: React.FunctionComponent<PageStateProxyProps> = (props) => {
    const pageStateContext = React.useContext(PageStateContext);

    React.useEffect(() => {
        pageStateContext.setPageState(props.stepWizardProps.currentStep, {isActive: props.stepWizardProps.isActive, ...props})
    }, [...Object.values(props?.userUpdates || {}), // eslint-disable-line react-hooks/exhaustive-deps
        props.stepWizardProps.isActive,
        props.canNavigateBack,
        props.canNavigateForward,
        props.nextButtonLabel,
        props.showLearnMore,
        props.useDarkTheme
    ]);

    return (
        <div/>
    );
}

export default PageStateProxy;
