import _, { isArray } from "lodash";
import { IUser } from "../models";
const { NODE_ENV } = process.env;

let baseUrl = NODE_ENV === "production" ? "/api/" : "http://localhost:7071/api/";
let apiKey: string = "BSLwOvx5G2jjGXEI";

export function setBaseUrl(url: string) {
	baseUrl = url;
}

export function setApiKey(key: string) {
	apiKey = key;
}

export const findUser = createEndpointWithResponse<string, IUser>("finduser/{0}");
export const findIpsosUser = createEndpointWithResponse<string, string, IUser>("findipsosuser/{0}/{1}");
export const updateUser = createEndpointWithPayload<string, IUser, IUser>("user/{0}", "POST");
export const getClientKey = createEndpointWithResponse<{ key: string }>("getclientkey");
export const getVehInfo = createEndpointWithResponse<string, any>("getvehinfo/{0}");
export const getUserVehicles = createEndpointWithResponse<string, any>("getuservehicles/{0}");
export const addGeoTollUser = createEndpointWithPayload<string, IUser, any>("addgeotolluser/{0}", "POST");
export const getVINSummary = createEndpointWithResponse<string, any>("vinsummary/{0}", "GET");

function createEndpointWithResponse<TResponse>(endpoint: string, method?: "GET" | "POST"): () => Promise<TResponse>;
function createEndpointWithResponse<TRequest, TResponse>(
	endpoint: string,
	method?: "GET" | "POST"
): (request: TRequest) => Promise<TResponse>;
function createEndpointWithResponse<TRequest, TRequest2, TResponse>(
	endpoint: string,
	method?: "GET" | "POST"
): (request: TRequest, request2: TRequest2) => Promise<TResponse>;
function createEndpointWithResponse<TRequest, TRequest2, TResponse>(
	endpoint: string,
	method?: "GET" | "POST"
): (request: TRequest, request2: TRequest2) => Promise<TResponse> {
	return async (request?: TRequest, request2?: TRequest2): Promise<TResponse> => {
		return await executeRequest(endpoint, method || "GET", [request, request2]);
	};
}

function createEndpointWithPayload<TRequest, TResponse>(
	endpoint: string,
	method?: "GET" | "POST"
): (request: TRequest) => Promise<TResponse>;
function createEndpointWithPayload<TRequest, TPayload, TResponse>(
	endpoint: string,
	method?: "GET" | "POST"
): (request: TRequest, payload: TPayload) => Promise<TResponse>;
function createEndpointWithPayload<TRequest, TPayload, TResponse>(
	endpoint: string,
	method?: "GET" | "POST"
): (request: TRequest, payload: TPayload) => Promise<TResponse> {
	return async (request?: TRequest, payload?: TPayload): Promise<TResponse> => {
		return await executeRequest(endpoint, method || "GET", request, payload);
	};
}

function formatString(formatString: string, ...replacements: string[]): string {
	const args = arguments;
	return formatString.replace(/{(\d+)}/g, function (match, value) {
		const index = Number(value) + 1;
		return typeof args[index] != "undefined" ? args[index] : match;
	});
}

function fixKeys(obj: any) {
	if (_.isArray(obj)) {
		obj.forEach((item) => {
			fixKeys(item);
		});
	} else {
		Object.keys(obj).forEach((key) => {
			if (key === "RowKey") {
				obj.rowKey = obj[key];
				delete obj[key];
			} else if (key === "PartitionKey") {
				obj.partitionKey = obj[key];
				delete obj[key];
			}
		});
	}
}

const executeRequest = async (url: string, method: "GET" | "POST", urlParameters: any, payload: any = null) => {
	const formattedUrl = isArray(urlParameters)
		? formatString(url, ...urlParameters)
		: formatString(url, urlParameters);
	let fullUrl = baseUrl + formattedUrl + "?code=" + apiKey;

	console.log(`Calling ${fullUrl}`);
	return fetch(fullUrl, {
		method,
		mode: "cors",
		headers: {
			"x-functions-key": apiKey,
			accept: "application/json",
			"Content-Type": "application/json",
		},
		body: payload && JSON.stringify(payload, (k, v) => (v === undefined ? null : v)),
	})
		.then(async (response) => {
			if (response.status < 200 || response.status >= 300) {
				const error = Error(`${response.status}: [${response.statusText}]`);
				error.message = (await response.json())["Message"];
				error.name = "ServiceError";
				console.log(error);
				throw error;
			}

			const contentType = response.headers.get("content-type");
			if (!contentType || contentType.indexOf("/json") === -1) {
				const error = Error("Service returned an unexpected content-type: " + contentType);

				console.log(response.body);
				console.log(response.json());
				throw error;
			}

			return response.json();
		})
		.then((responseJson) => {
			console.log(responseJson);
			if (!Array.isArray(responseJson) && Object.keys(responseJson).length === 0) {
				return null;
			}
			fixKeys(responseJson);
			return responseJson;
		});
};
