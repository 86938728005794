import "./ParticipantAgreement.css";
export default function ParticipantAgreement() {
	return (
		<div className="participant-agreement">
			<h2>Kansas Midwest Road Usage Charge (RUC) Pilot Participation Agreement</h2>
			<p className="hint">Last updated: March 15, 2024</p>
			<p>
				Read these policies before volunteering to report mileage in the Kansas Midwest Road Usage Charge (RUC)
				Pilot (Pilot).
			</p>
			<p>
				In this Participation Agreement, “we” refers to the team of researchers, consultants, and technology
				providers responsible for conducting the Pilot project and study on behalf of the Kansas Department of
				Transportation (KDOT). “You” refers to individuals that have chosen to participate in the Pilot.
			</p>
			<p>
				For an overview of the Kansas Midwest RUC Pilot and Study, visit the Pilot website at{" "}
				<a href="https://www.midwestruc.org" target="_blank" rel="noopener noreferrer">
					www.midwestruc.org
				</a>
				.
			</p>
			<p>
				By signing this agreement, you are volunteering to participate in a 3-month road usage charge Pilot. You
				may choose how you report mileage in this Pilot. These options include the following:
			</p>
			<ul>
				<li>Online form (entering your odometer reading online)</li>
				<li>Image capture (submitting a photo of your odometer using GeoToll Smartphone app)</li>

				<li>GeoToll Smartphone App (automatically reporting mileage using location)</li>
				<li>Direct vehicle (automatically sharing mileage using your vehicle's technology with GeoToll)</li>
			</ul>
			<p>
				Your participation will provide valuable data for researching road usage charging in Kansas. Throughout
				the 3-month Pilot, you will provide feedback about your experience in the Pilot. There is no cost to you
				to participate.
			</p>
			<p>
				The policies described below will help ensure that the goals of this project are met, and that your
				personal information, including mileage and vehicle data, is used only as you authorize.
			</p>
			<p>
				Before agreeing to participate in the Kansas Midwest RUC Pilot, read this Participation Agreement and
				Privacy Policy. Let us know if you have any questions by emailing us at{" "}
				<a href="mailto:contact@midwestruc.org">contact@midwestruc.org</a> or calling us at{" "}
				<a href="tel:1-866-782-2024">1-866-782-2024</a>. Do not click “accept” until you have reviewed and
				understand both this Participation Agreement and the Privacy Policy.{" "}
				<i>
					By clicking on “accept”, you are confirming that you have reviewed, understand, and voluntarily
					accept this Participation Agreement and the Kansas Midwest RUC Pilot Privacy Policy.
				</i>
			</p>
			<br></br>
			<h3>Participation Requirements</h3>
			<h4>We need your participation for the full 3 months of the Kansas Midwest RUC Pilot.</h4>
			<p>
				We need your participation for the full 3-month duration of the Pilot. “Participation” requires all of
				the following:
			</p>
			<ol>
				<li>setting up a test account and enrolling a vehicle in the Pilot;</li>
				<li>
					choosing how you report your mileage, and taking steps to set up your mileage reporting (for
					example, downloading the smartphone app and submitting an initial photo of your odometer mileage);
				</li>
				<li>reporting your mileage during the Pilot using the mileage reporting method you selected;</li>
				<li>responding to surveys during the Pilot project; an</li>
				<li>following any necessary close-out steps.</li>
			</ol>
			<h4>
				Your vehicle must be properly registered in Kansas or a participating Midwest state to be enrolled in
				the Pilot.
			</h4>
			<p>
				Only vehicles properly registered in Kansas or a participating Midwest state are eligible for the Pilot.
				You must notify us if you sell any vehicle enrolled in the Pilot Project, or if your vehicle is
				undergoing repairs that will last more than five (5) days so that we understand why no mileage is being
				reported. Additionally, you may only enroll one vehicle in this Pilot
			</p>
			<h4>
				You must provide your personal contact and vehicle information to enroll in the Kansas Midwest RUC Pilot
				Project.
			</h4>
			<p>
				“Enrollment” requires you to sign up to establish your road usage charge account and choose your
				preferred mileage reporting method. You must provide all information required to set up your Pilot
				account. You will need to provide your personal contact and other information so that we can provide
				direct customer support, including assisting you with the mileage reporting method you have chosen.
			</p>
			<p>The following information will be required:</p>
			<ul>
				<li>Your full name</li>
				<li>Your email address</li>
				<li>Year, make and model of vehicles you own or lease</li>
				<li>
					Fuel type used by your vehicles (gasoline, diesel, electric or combination, e.g., hybrid/plug-in
					hybrid)
				</li>
				<li>The vehicle identification number (VIN) for the vehicle you will enroll in the Pilot</li>
				<li>The state and county of your residence</li>
				<li>The state of your vehicle registration</li>
			</ul>
			<p>
				If you choose to use the smartphone method to report your mileage during the Pilot, the following
				additional information will also be needed
			</p>
			<ul>
				<li>Your Smartphone Android or iOS Operating System (OS)</li>
				<li>Your Bluetooth Identification Number (BTUUID) for the vehicle(s) enrolled in the Pilot.</li>
			</ul>
			<p>
				Occasionally, we may need to use your personal contact information to contact you or send important
				notices about your mileage account, changes in the Pilot, surveys, incentives, or changes to these
				policies.
			</p>
			<h4>
				Mileage reporting requirements will depend on the method you choose; location-based services are
				optional.
			</h4>
			<p>
				Your vehicle location details are not required to participate in the Pilot. If you do not want location
				information collected, during Pilot enrollment you can choose a mileage reporting option that does not
				use GPS location-based services. However, if you choose the non-location mileage reporting option, miles
				driven out of state or on private roads cannot be deducted from the simulated road usage charges. Even
				if you choose a mileage reporting technology that uses GPS location-based information to properly deduct
				non-taxable miles driven, no detailed location information (e.g., routes taken) will be provided by us
				to KDOT, other participating states, or any third party.
			</p>
			<h4>You do not have to pay anything when you receive your periodic road usage charge report.</h4>
			<p>
				Participants will receive periodic statements or mileage reports of their simulated road usage charges
				based on their vehicle's miles traveled. The purpose of these mileage reports is to illustrate what
				charges would be owed (if any) if road usage charges were adopted as a replacement for the state's gas
				tax or electric vehicle registration surcharges. No actual payments are due from Participants.
			</p>
			<h4>You must complete all Pilot requirements to receive your $100 Visa gift card.</h4>
			<p>
				After you have agreed to these terms of participation and completed enrollment, you can earn up to $100
				in the form of a Visa gift card.
			</p>
			<p>
				You will receive all earnings in the form of a single Visa gift card to be issued by our payment
				partner, Tremendous. This gift card will be sent after the Pilot ends.
			</p>
			<p>
				Only Participants in Kansas can receive compensation for participating in the Pilot. Researchers,
				contractors, consultants, and vendors supporting the Kansas Midwest RUC Pilot, are not eligible to
				receive the Visa gift card.
			</p>
			<h4>We will use your personal and other information only for authorized purposes.</h4>
			<p>
				The Kansas Midwest RUC Pilot project has adopted a Privacy Policy to let you know how your personal and
				other information will be collected, used, and shared. You must read this Privacy Policy before agreeing
				to participate in the Project.
			</p>
			<h4>You retain all rights to access the information in your Kansas Midwest RUC Pilot test account.</h4>
			<p>
				During the 3-month test period, we may review Pilot accounts at any time. You have the right to see and
				review your Pilot account maintained as well as all current information associated with it. Any errors
				or omissions you report to us will be investigated and if warranted, corrections will be made. You must
				not share the password to your Pilot account with anyone
			</p>
			<h4>We will destroy any personal information after the conclusion of the Pilot.</h4>
			<p>
				All personally identifiable information collected to set up and manage your account, including mileage
				and other data collected during the Pilot, will be destroyed within 30 days of the completion of the
				Kansas Midwest RUC study. Non-personal information (e.g., total mileage, vehicle make and model, etc.)
				may be retained indefinitely and used for other road usage charge research purposes only. All data will
				be anonymized, securely stored, and only individuals conducting Pilot operations will have access to the
				data.
			</p>
			<h4>
				If you stop participating in the Pilot project or engage in activities that jeopardize others or the
				research itself, we may revoke your participation.
			</h4>
			<p>Your participation privileges in the Kansas Midwest RUC Pilot project may be revoked if you:</p>
			<ul>
				<li>fail to report mileage driven as required;</li>
				<li>
					fail to take necessary steps to initiate your chosen mileage reporting method (e.g., submit an
					odometer photo, download the smartphone app, etc.);
				</li>
				<li>tamper with any mileage reporting method, including your vehicle’s odometer;</li>
				<li>
					intentionally report false test account information, mileage data, or other required information; or
				</li>
				<li>you no longer own the vehicle you enrolled in the Kansas Midwest RUC Pilot project.</li>
			</ul>
			<h4>
				If you click “accept”, you agree with all policies, including our use of your demographic and driving
				information.
			</h4>
			<p>By clicking "Accept:</p>
			<ol>
				<li>
					You agree to fully participate in the Kansas Midwest RUC Pilot for the designated period, beginning
					with the date of your enrollment in the Pilot and ending with the completion of the final Pilot
					survey (a period of approximately three (3) months).
				</li>
				<li>You agree to select a mileage reporting option and to provide periodic mileage data.</li>
				<li>
					If you choose to report mileage using GeoToll's smartphone app or your automaker's technology, you
					agree to review and accept the terms and conditions for the use of the technology and services. If
					you do not agree with the terms and conditions for the use of GeoToll or your automaker's
					technology, you agree to select a different option for reporting your miles.
				</li>
				<li>
					You understand that you will receive (via email) a periodic road usage charge mileage report
					outlining the amount of the simulated road usage charge (based on miles reported) your vehicle
					incurred, contrasted with fuel taxes paid or electric vehicle registration surcharge amounts (if
					applicable).
				</li>
				<li>
					You will report any difficulties or malfunctions in the mileage reporting option selected and report
					any road usage charge account statement errors to us in a timely manner and allow corrections to be
					made.
				</li>
				<li>You agree not to purposefully attempt to defraud the Pilot.</li>

				<li>
					You will fully participate in periodic surveys and will provide accurate answers to questions
					related to your experience as a participant in the Pilot. You authorize your email address to be
					used by us to administer the surveys.
				</li>
				<li>
					You are allowing your demographic and personal information, including your mileage and vehicle data,
					to be used for research purposes only as specified in this Participant Agreement and Privacy Policy
				</li>
			</ol>
			<h2>Kansas Midwest Road Usage Charge Pilot Privacy Policy</h2>
			<p className="hint">Last updated: March 15, 2024</p>
			<p>
				In this Privacy Policy, “we” refers to the team of researchers, consultants, and technology providers
				responsible for conducting the Pilot project and study on behalf of the Kansas Department of
				Transportation. “You” refers to individuals that have chosen to participate in the Pilot.
			</p>
			<h4>What is "Personal Information"?</h4>
			<p>
				Personal information is any information about a person which, on its own or when combined with other
				information, is reasonably capable of revealing the identity or activities of that person.
				<br />
				Personal information includes items such as a person's name, address, telephone number, email address,
				account numbers, travel or trip details, and similar information associated with a specific person.
			</p>
			<h4>How your Personal Information will be collected and used.</h4>
			<p>
				Since this Kansas Midwest Road Usage Charge Pilot is being conducted for research purposes, we may ask
				for demographic information to help us better understand how a future road usage charge system might
				affect people differently, depending on where they live, their gender, ethnicity, general income
				bracket, etc. This kind of demographic information would not be needed if this were an actual live road
				usage charge system.
				<br />
				For research purposes only, and retained only in an aggregated and anonymized form, we may collect
				demographic information, including but not limited to:
			</p>
			<ul>
				<li>
					Information about the number of vehicles driven by your household, whether they are owned or leased,
					type of fuel used, etc.
				</li>
				<li>
					Characteristics of drivers in your household, such as age range (for example, age 18-45, or 46-65,
					etc.), primary uses of the vehicle (business/personal), general occupation, income range (for
					example, under $30,000 per year, or between $30,000 and $60,000 per year), etc.
				</li>
				<li>Information about the general area you live in, housing type, distance from work, etc.</li>
			</ul>
			<p>
				In order to calculate your estimated road usage charges during the Pilot and send you periodic reports,
				we will collect:
			</p>
			<ul>
				<li>
					Your full name and contact information, including your zip code and county, and your email address
				</li>
				<li>The Vehicle Identification Number (VIN) for the vehicle you will enroll in the Pilot</li>
				<li>Year, make, and model of the vehicle you enroll in the Pilot</li>
				<li>
					Gross vehicle weight of the vehicle you enroll in the Pilot (for medium-duty or heavy- duty vehicle
					only)
				</li>
				<li>Odometer mileage of the vehicle enrolled in the Pilot</li>
			</ul>
			<p>
				Occasionally, we may use your personal information to contact you or send important notices about your
				account, changes in the Pilot, participant surveys, participant rewards, or changes to these policies.
			</p>
			<h4>General location information about your vehicle is entirely optional.</h4>
			<p>
				Your vehicle location details are not required to participate in the Pilot. If you do not want location
				information collected, during Pilot enrollment you can choose a mileage reporting option that does not
				use location-based services.
				<br />
				If you choose a mileage reporting method that does use location-based information to deduct non-taxable
				miles from your account, we will only use that location information to determine the appropriate
				per-mile rate to apply to your mileage.
			</p>
			<h4>We will protect against unauthorized use of your personal information.</h4>
			<p>
				We will not use or share your personal information for any purpose unrelated to the Pilot unless
				necessary to comply with a subpoena, court order or disclosure required under the Kansas State law KSA
				45-216 (Kansas Open Records Act).
				<br />
				If we receive a demand for disclosure of any data or information that includes your personal
				information, within 10 days we will notify you that we have received such demand, providing you an
				opportunity to object to its release.
			</p>
			<h4>We will destroy all Personal Information we collect within 30 days of the end of the Pilot.</h4>
			<p>
				To ensure your personal information and data is not kept longer than necessary, all personal information
				we collect during the course of the Pilot will be permanently destroyed within 30 days of the conclusion
				of the Pilot.
				<br />
				After this period, we will retain non-personal information (i.e., total mileage, vehicle make and model,
				etc.) only in an anonymized and aggregated form (meaning, everyone's driving data and information will
				be stripped of personal identifiers and will be grouped together to be used for statistical research and
				analysis purposes).
			</p>
		</div>
	);
}
