import { Box, Button, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import _ from "lodash";
import React from "react";
import StepWizard from "react-step-wizard";
import { ContentMarginBox, LearnMoreDrawer } from "./components";
import { PageStateContext, ShowLearnMoreContext, ShowSummaryContext, UserContext } from "./contexts";
import * as Globals from "./Globals";
import { IPageState, IUser, ReportingOptions, RucRate } from "./models";
import { MileageReportingPage, ReportingOptionsPage, ReviewPage, VehicleInfoPage, WelcomePage } from "./pages";
import { ConfirmationPage } from "./pages/ConfirmationPage";
import * as Server from "./server";
import * as Themes from "./themes";
import Animate from "./themes/animate.module.css";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "./AppInsights";
import { Header } from "./components/Header/Header";

const nextOnlyAnimations = {
	enterRight: `${Animate.animated} ${Animate.fadeInRight}`,
	enterLeft: `${Animate.instant} ${Animate.fadeInLeft}`,
	exitRight: `${Animate.instant} ${Animate.fadeOutRight}`,
	exitLeft: `${Animate.animated} ${Animate.fadeOutLeft}`,
};

const noAnimations = {
	enterRight: `${Animate.instant} ${Animate.fadeInRight}`,
	enterLeft: `${Animate.instant} ${Animate.fadeInLeft}`,
	exitRight: `${Animate.instant} ${Animate.fadeOutRight}`,
	exitLeft: `${Animate.instant} ${Animate.fadeOutLeft}`,
};

const allAnimations = {
	enterRight: `${Animate.animated} ${Animate.fadeInRight}`,
	enterLeft: `${Animate.animated} ${Animate.fadeInLeft}`,
	exitRight: `${Animate.animated} ${Animate.fadeOutRight}`,
	exitLeft: `${Animate.animated} ${Animate.fadeOutLeft}`,
};

function App() {
	const [stepWizard, setStepWizard] = React.useState<any>(null);
	const [user, setUser] = React.useState<IUser | null>(null);
	const [percentDone, setPercentDone] = React.useState(0);
	const [activeStep, setActiveStep] = React.useState(1);
	const [showSummary, setShowSummary] = React.useState(false);
	const [pageStates, setPageStates] = React.useState<{ [index: number]: IPageState }>({});
	const pageState = React.useRef<IPageState | undefined>(undefined);
	const [contentWidth, setContentWidth] = React.useState(0);
	const [showLearnMore, setShowLearnMore] = React.useState(false);
	const [canAdvance, setCanAdvance] = React.useState(true);
	const [serverIsReady, setServerIsReady] = React.useState(false);
	const contentRef = React.useRef<HTMLElement>();

	const pruneUnchanged = (newUser: IUser, oldUser: IUser) => {
		return _.transform(newUser, (result: any, value, key) => {
			if (!_.isEqual(value, oldUser[key])) {
				result[key] = value;
			}
		});
	};

	const userContext = {
		user,
		setUser: (newUser: IUser | null) => {
			if (newUser) {
				if (!newUser.id) {
					newUser.id = (newUser as any).rowKey;
				}
				newUser.rucRate = function () {
					let result: number = 0.0;
					if (RucRate[this.state]) {
						if (this.veh_weight && RucRate[this.state][this.veh_weight]) {
							result = RucRate[this.state][this.veh_weight];
						} else {
							result = RucRate[this.state]["Base"];
						}
					}
					return result;
				};
				newUser.yearsOld = function () {
					if (this.vehyear) {
						return Math.max(new Date().getFullYear() + 1 - this.vehyear, 1);
					}
					return 1;
				};
				newUser.effectiveExemptMiles = function () {
					return this.exempt_mi ? this.exempt_mi : 0;
				};
				newUser.chargeableMiles = function () {
					const miles = this.mi_est ?? 0;

					if (this.hasAllowedExemption()) {
						return Math.max(0, miles - this.effectiveExemptMiles());
					}
					return miles;
				};
				newUser.hasAllowedExemption = function () {
					return (
						this.effectiveExemptMiles() > 0 &&
						(this.mro_selection == ReportingOptions.Smartapp.value ||
							this.mro_selection == ReportingOptions.Telematics.value)
					);
				};

				appInsights.setAuthenticatedUserContext(newUser.id);
			} else {
				appInsights.clearAuthenticatedUserContext();
			}
			setUser(newUser);
		},
		updateUser: async (newUser: Partial<IUser>) => {
			if (user && newUser) {
				// Prune unchanged fields
				newUser = pruneUnchanged(newUser as IUser, user);

				if (Object.keys(newUser).length > 0) {
					const updatedUser = await Server.updateUser(user.id, newUser as IUser);
					userContext.setUser(updatedUser);
					return !!updatedUser?.id;
				}
			}

			return true;
		},
	};

	const pageStatesContext = {
		setPageState: (step: number, state: IPageState) => {
			const copy = { ...pageStates };
			copy[step] = state;
			setPageStates(copy);

			if (state.isActive) {
				pageState.current = state;
			}
		},
	};

	const showSummaryContext = {
		showSummary,
		panelWidth: 300,
		setShowSummary: (value: boolean) => {
			setShowSummary(value);
			appInsights.trackEvent({ name: value ? "ShowSummary" : "HideSummary" }, { page: getStepName() });
		},
	};

	const getStepName = (step = activeStep) => {
		const steps = stepWizard.getSteps();
		if (step <= steps.length) {
			return steps[step - 1].props.stepName;
		}

		return null;
	};

	const getNextStepName = (): string => {
		return getStepName(activeStep + 1);
	};

	const showLearnMoreContext = {
		showLearnMore,
		setShowLearnMore: (value: boolean) => {
			setShowLearnMore(value);
			appInsights.trackEvent({ name: value ? "ShowLearnMore" : "HideLearnMore" }, { page: getStepName() });
		},
	};

	const handleStepChange = (stepChange: { previousStep: number; activeStep: number }) => {
		window.scrollTo(0, 0);
		setActiveStep(stepChange.activeStep);
		setPercentDone(((stepChange.activeStep - 1) / (stepWizard.totalSteps - 2)) * 100);
		setShowLearnMore(false);
		if (stepChange.activeStep === user?.sim_step) {
			setCanAdvance(false);
			setTimeout(() => {
				setCanAdvance(true);
			}, Globals.pageAdvanceMinTime);
		}

		const stepName = getStepName(stepChange.activeStep);
		appInsights.trackPageView({
			name: stepName,
			uri: stepChange.activeStep.toString(),
			refUri: stepChange.previousStep.toString(),
		});
	};

	const abortClick = async () => {
		let proceed = true;
		if (pageState.current) {
			if (pageState.current.abortOnClick) {
				proceed = await pageState.current.abortOnClick();
			}

			if (proceed) {
				await previousPage();
			}
		}
	};

	const acceptClick = async (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		let proceed = true;
		if (pageState.current) {
			if (pageState.current.acceptOnClick) {
				proceed = await pageState.current.acceptOnClick();
			}

			if (proceed) {
				await nextPage();
			}
		}
	};

	const previousPage = async () => {
		stepWizard.previousStep();
	};

	const nextPage = async () => {
		const userData = pageState.current?.userUpdates || {};
		const nextStep = activeStep + 1;
		const stepName = getStepName(nextStep);
		if (nextStep > (user?.sim_step || 0) && stepName) {
			userData.sim_step = nextStep;
			userData.sim_status = stepName;
		}
		await userContext.updateUser(userData);
		stepWizard.nextStep();
	};

	const updateContentWidth = () => {
		const element = contentRef?.current as HTMLElement;
		if (element) {
			setContentWidth(element.clientWidth + element.offsetLeft + 64);
		}
	};

	React.useEffect(() => {
		window.addEventListener("resize", updateContentWidth);
		Server.getClientKey().then((response) => {
			if (response && response.key) {
				Server.setApiKey(response.key);
			}
			setServerIsReady(true);
		});
		return () => {
			window.removeEventListener("resize", updateContentWidth);
		};
	}, []);

	React.useEffect(() => {
		updateContentWidth();
	}, [showSummaryContext.showSummary, pageState.current?.showCostEstimate]);

	return (
		<React.StrictMode>
			<CssBaseline />
			<ThemeProvider theme={Themes.Light}>
				<UserContext.Provider value={userContext}>
					<ShowLearnMoreContext.Provider value={showLearnMoreContext}>
						<ShowSummaryContext.Provider value={showSummaryContext}>
							<PageStateContext.Provider value={pageStatesContext}>
								<AppInsightsContext.Provider value={reactPlugin}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										<Box flexGrow={1} display="flex" flexDirection="column">
											<ContentMarginBox
												isHeader={true}
												sx={{
													marginBottom: "-7.125rem",
													height: "7.125rem",
													zIndex: 10,
													position: "fixed",
													backgroundColor: pageState.current?.useDarkTheme
														? "#002856;"
														: "white",
												}}
											>
												<Grid
													flexGrow={1}
													container
													sx={{ marginX: "auto", marginTop: "1.4375rem" }}
												>
													<Header
														useDarkTheme={pageState.current?.useDarkTheme}
														showLogo={pageState.current?.showLogo}
													/>
												</Grid>
											</ContentMarginBox>
											{serverIsReady && (
												<StepWizard
													onStepChange={handleStepChange}
													instance={setStepWizard}
													isLazyMount={false}
													transitions={
														activeStep > 2
															? allAnimations
															: activeStep === 2
															? nextOnlyAnimations
															: noAnimations
													}
												>
													<WelcomePage
														nextPage={nextPage}
														previousPage={previousPage}
														nextPageName={getNextStepName}
													/>
													<VehicleInfoPage
														nextPage={nextPage}
														previousPage={previousPage}
														nextPageName={getNextStepName}
													/>
													<MileageReportingPage
														nextPage={nextPage}
														previousPage={previousPage}
														nextPageName={getNextStepName}
													/>
													<ReportingOptionsPage
														nextPage={nextPage}
														previousPage={previousPage}
														nextPageName={getNextStepName}
													/>
													<ReviewPage
														nextPage={nextPage}
														previousPage={previousPage}
														nextPageName={getNextStepName}
													/>
													<ConfirmationPage
														nextPage={nextPage}
														previousPage={previousPage}
														nextPageName={getNextStepName}
													/>
												</StepWizard>
											)}
											<ContentMarginBox
												isHeader={false}
												sx={{
													marginTop: "-12rem",
													height: "7.125rem",
													pointerEvents: "none",
													zIndex: 10,
													display: { xs: "none", lg: "block" },
												}}
											>
												<Grid xs={4} md={3} id="trackme">
													<Box
														sx={{ display: "flex", justifyContent: "right", height: 0 }}
														ref={contentRef}
													/>
												</Grid>
											</ContentMarginBox>
											<LearnMoreDrawer
												open={showLearnMore}
												onClose={() => showLearnMoreContext.setShowLearnMore(false)}
												summaryShown={showSummary && !!pageState.current?.showCostEstimate}
											>
												{pageState.current?.learnMoreContent}
											</LearnMoreDrawer>
										</Box>
									</Box>
									<Box
										sx={{
											position: "fixed",
											bottom: 0,
											width: "100%",
											zIndex: 10,
											visibility: pageState.current?.showNavigationButtons ? "visible" : "hidden",
										}}
										bgcolor="background.default"
									>
										<Box
											sx={{
												height: "0.5rem",
												visibility: pageState.current?.showProgress ? "visible" : "hidden",
												backgroundColor: "#E6E6E6",
												borderTopStyle: "solid",
												borderTopWidth: "0.0625rem",
												borderColor: "action.disabled",
												zIndex: 10,
											}}
										>
											<Box
												sx={{
													height: "0.5rem",
													backgroundColor: "#95C93D",
													width: percentDone.toString() + "%",
												}}
											></Box>
										</Box>
										<Box
											sx={{
												width: { xs: "96%", lg: contentWidth },
												marginY: "1.5rem",
												position: "relative",
											}}
											id="buttontracker"
										>
											<Box sx={{ display: "flex", justifyContent: "right", gridGap: "1.75rem" }}>
												<Button
													sx={{
														minWidth: 0,
														fontSize: "1.125rem",
														visibility: pageState.current?.showBackButton
															? "inherit"
															: "hidden",
													}}
													onClick={abortClick}
													disabled={!pageState.current?.canNavigateBack}
												>
													Previous
												</Button>
												<Button
													sx={{
														minWidth: 0,
														fontSize: "1.125rem",
														visibility: pageState.current?.showNextButton
															? "inherit"
															: "hidden",
													}}
													type="submit"
													onClick={acceptClick}
													variant="contained"
													disabled={!canAdvance || !pageState.current?.canNavigateForward}
												>
													{pageState.current?.nextButtonLabel || "Next"}
												</Button>
											</Box>
										</Box>
									</Box>
								</AppInsightsContext.Provider>
							</PageStateContext.Provider>
						</ShowSummaryContext.Provider>
					</ShowLearnMoreContext.Provider>
				</UserContext.Provider>
			</ThemeProvider>
		</React.StrictMode>
	);
}

export default App;
