import { Colors, setCommon } from './Common';

export const Dark = setCommon(Colors.white, {
    spacing: 16,
    typography: {
        body1: {
            fontWeight: 400,
            // lineHeight: '1.175rem' didn't work with the grid?
        },
        h1: {
            fontWeight: 700,
            fontSize: '2rem',
            lineHeight: '1.5'
        },
        h2: {
            color: Colors.kansasSkyBlue,
            fontWeight: 600,
            fontSize: '1.125rem !important',
            lineHeight: '1.77',
            textTransform: 'uppercase'

        },
        h3: {
            fontWeight: 500,
            fontSize: '1.313rem',
            // lineHeight: '1.555rem'
        },
        h4: {
            fontWeight: 600,
            fontSize: '1.313rem',
            // lineHeight: '1.555rem'
        }
    },
    palette: {
        mode: "dark",
        // primary: {
        //     main: Colors.pilotYellow,
        //     dark: Colors.pilotBrown,
        //     light: Colors.pilotBrown,
        //     contrastText: Colors.pilotBlue
        // },
        primary: {
            main: Colors.actionGreen,
            dark: Colors.pilotBrown,
            light: Colors.pilotBrown,
            contrastText: Colors.pilotBlue
        },
        secondary: {
            main: Colors.blue2,
            dark: Colors.blue3,
            light: Colors.blue1,
            contrastText: Colors.actionBlue
        },
        error: {
            main: Colors.red
        },
        success: {
            main: Colors.pilotGreen
        },
        background: {
            paper: Colors.bgBlue,
            default: Colors.bgBlue
        },
        action: {
            disabled: Colors.gray3
        },
        divider: Colors.pilotYellow
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(12, 31, 43, 0.9)"
                }
            }
        }
    },
    maxHeaderWidth: '74rem'
});

