export interface IMenuItem {
    value: string;
    text: string;
}

export const SimSteps = {
    Welcome: { name: 'Welcome', stepNumber: 1 },
    VehicleInfo: { name: 'VehicleInfo', stepNumber: 2 },
    MileageReporting: { name: 'MileageReporting', stepNumber: 3 },
    ReportingOptions: { name: 'ReportingOptions', stepNumber: 4 },
    Review: { name: 'Review', stepNumber: 5 },
    Confirmation: { name: 'Confirmation', stepNumber: 6 },
};

//RUC rates by vehicle class
export const RucRate: { [id: string]: { [id: string]: number } } = {
    KS: {
        Base: 0.012, // Filtered out of vehicle weight selection
        '10,001 - 26,000 lbs': 0.031,
        '26,001 - 52,000 lbs': 0.013,
        '52,001 - 80,000 lbs': 0.013,
        '> 80,000 lbs': 0.013,
    },
    MN: {
        Base: 0.014, // Filtered out of vehicle weight selection
        '10,001- 26,000 lbs': 0.037,
        '26,001 - 52,000 lbs': 0.013,
        '52,001 - 80,000 lbs': 0.013,
        '> 80,000 lbs': 0.013,
    },
};

export const VehicleWeightsByState: { [id: string]: IMenuItem[] } = Object.keys(RucRate).reduce((obj, state) => {
    obj[state] = Object.keys((RucRate as any)[state])
        .filter((key) => key !== 'Base')
        .map((weight) => {
            return { value: weight, text: weight };
        });
    return obj;
}, {} as any);

export const ReportingOptions = {
    Manual: { value: 'manual', text: 'Online Form' },
    Photo: { value: 'simpleapp', text: 'Image Capture' },
    Smartapp: { value: 'smartapp', text: 'Smartphone App' },
    Telematics: { value: 'telematics', text: 'Direct Vehicle' },
};

export const SurveyUrl = {
    Manual: 'https://google.com/',
    Other: 'https://yahoo.com/',
};

export const Compensation = {
    Manual: '$30',
    KS: '$30',
    MN: '$[MN-amount]',
};

export const Followup = {
    email: '[email address or account]',
    timeframe: '[timeframe]',
};

export const CountyMileage = {
    KS: {
        Allen: 1090,
        Anderson: 1190,
        Atchison: 1090,
        Barber: 1180,
        Barton: 1060,
        Bourbon: 1120,
        Brown: 1100,
        Butler: 1090,
        Chase: 1160,
        Chautauqua: 1220,
        'Cherokee ': 1130,
        Cheyenne: 1080,
        Clark: 1170,
        Clay: 1100,
        Cloud: 1070,
        Coffey: 1190,
        Comanche: 1240,
        Cowley: 1090,
        Crawford: 1080,
        Decatur: 1100,
        Dickinson: 1200,
        Doniphan: 1150,
        Douglas: 1040,
        Edwards: 1210,
        Elk: 1330,
        Ellis: 1040,
        Ellsworth: 1190,
        Finney: 1090,
        Ford: 1020,
        Franklin: 1180,
        Geary: 1080,
        Gove: 1200,
        Graham: 1150,
        Grant: 1160,
        Gray: 1230,
        Greeley: 1170,
        Greenwood: 1210,
        Hamilton: 1170,
        Harper: 1160,
        Harvey: 1110,
        Haskell: 1220,
        Hodgeman: 1180,
        Jackson: 1180,
        Jefferson: 1230,
        Jewell: 1190,
        Johnson: 1030,
        Kearny: 1200,
        Kingman: 1150,
        Kiowa: 1190,
        Labette: 1100,
        Lane: 1160,
        Leavenworth: 1100,
        Lincoln: 1180,
        Linn: 1310,
        Logan: 1150,
        Lyon: 1020,
        Marion: 1180,
        Marshall: 1120,
        Mcpherson: 1090,
        Meade: 1230,
        Miami: 1170,
        Mitchell: 1080,
        Montgomery: 1080,
        Morris: 1130,
        Morton: 1180,
        Nemaha: 1110,
        Neosho: 1090,
        Ness: 1200,
        Norton: 1030,
        Osage: 1230,
        Osborne: 1210,
        Ottawa: 1200,
        Pawnee: 1150,
        Phillips: 1030,
        Pottawatomie: 1180,
        Pratt: 1060,
        Rawlins: 1060,
        Reno: 1020,
        Republic: 1150,
        Rice: 1220,
        Riley: 1020,
        Rooks: 1130,
        Rush: 1190,
        Russell: 1170,
        Saline: 1060,
        Scott: 1070,
        Sedgwick: 1050,
        Seward: 1060,
        Shawnee: 1030,
        Sheridan: 1150,
        Sherman: 1000,
        Smith: 1160,
        Stafford: 1230,
        Stanton: 1220,
        Stevens: 1180,
        Sumner: 1180,
        Thomas: 1090,
        Trego: 1080,
        Wabaunsee: 1260,
        Wallace: 1220,
        Washington: 1190,
        Wichita: 1170,
        Wilson: 1180,
        Woodson: 1190,
        Wyandotte: 1090,
        STATEWIDE: 1070,
    },
    MN: {
        Aitkin: 1090,
        Anoka: 700,
        Becker: 880,
        Beltrami: 810,
        Benton: 1020,
        'Big Stone': 900,
        'Blue Earth': 840,
        Brown: 630,
        Carlton: 1160,
        Carver: 720,
        Cass: 1110,
        Chippewa: 910,
        Chisago: 1070,
        Clay: 1150,
        Clearwater: 960,
        Cook: 1290,
        Cottonwood: 850,
        'Crow Wing': 960,
        Dakota: 770,
        Dodge: 850,
        Douglas: 1070,
        Faribault: 1080,
        Fillmore: 810,
        Freeborn: 1280,
        Goodhue: 1110,
        Grant: 1230,
        Hennepin: 810,
        Houston: 760,
        Hubbard: 980,
        Isanti: 750,
        Itasca: 890,
        Jackson: 1290,
        Kanabec: 830,
        Kandiyohi: 870,
        Kittson: 1050,
        Koochiching: 790,
        'Lac Qui Parle': 960,
        Lake: 1250,
        'Lake Of The Woods': 820,
        'Le Sueur': 710,
        Lincoln: 840,
        Lyon: 850,
        Mahnomen: 1290,
        Marshall: 1070,
        Martin: 1050,
        Mcleod: 730,
        Meeker: 770,
        'Mille Lacs': 1250,
        Morrison: 1050,
        Mower: 840,
        Murray: 900,
        Nicollet: 1080,
        Nobles: 1080,
        Norman: 940,
        Olmsted: 830,
        'Otter Tail': 990,
        Pennington: 750,
        Pine: 1290,
        Pipestone: 920,
        Polk: 920,
        Pope: 970,
        Ramsey: 800,
        'Red Lake': 830,
        Redwood: 920,
        Renville: 1050,
        Rice: 950,
        Rock: 1280,
        Roseau: 730,
        Scott: 790,
        Sherburne: 760,
        Sibley: 890,
        'St Louis': 880,
        Stearns: 980,
        Steele: 1150,
        Stevens: 800,
        Swift: 960,
        Todd: 940,
        Traverse: 790,
        Wabasha: 750,
        Wadena: 820,
        Waseca: 770,
        Washington: 770,
        Watonwan: 1140,
        Wilkin: 1290,
        Winona: 1130,
        Wright: 880,
        'Yellow Medicine': 870,
        STATEWIDE: 860,
    },
};

export const CountiesByState = Object.keys(CountyMileage).reduce((obj, state) => {
    obj[state] = Object.keys((CountyMileage as any)[state]).map((county) => {
        return { value: county, text: county };
    });
    return obj;
}, {} as any);
