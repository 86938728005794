import * as React from "react";
import { IUser } from "../models";

export const UserContext = React.createContext<{
	user: IUser | null;
	setUser: (user: IUser | null, save?: boolean) => void;
	updateUser: (user: Partial<IUser>) => Promise<boolean>;
}>({
	user: null,
	setUser: (user: IUser | null, save?: boolean) => {},
	updateUser: async (user: Partial<IUser>) => {
		return true;
	},
});
