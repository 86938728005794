import { createSvgIcon } from '@mui/material';

export const ErrorIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <path d="M11.5983 3.64063C10.8285 2.30729 8.90396 2.30729 8.13416 3.64063L2.18785 13.9399C1.41805 15.2733 2.3803 16.9399 3.9199 16.9399H15.8125C17.3521 16.9399 18.3144 15.2733 17.5446 13.9399L11.5983 3.64063Z" stroke="#D63705" stroke-width="2"/>
        <path d="M10.6077 11.4676H9.13771L8.89464 6.39795H10.8392L10.6077 11.4676ZM9.86691 12.5209C10.1601 12.5209 10.4071 12.6251 10.6077 12.8334C10.8083 13.034 10.9086 13.2771 10.9086 13.5626C10.9086 13.8558 10.8083 14.1066 10.6077 14.315C10.4071 14.5156 10.1601 14.6159 9.86691 14.6159C9.5814 14.6159 9.33448 14.5156 9.12613 14.315C8.92551 14.1066 8.8252 13.8558 8.8252 13.5626C8.8252 13.2771 8.92551 13.034 9.12613 12.8334C9.33448 12.6251 9.5814 12.5209 9.86691 12.5209Z" fill="#D63705"/>
    </svg>
    ,
    "ErrorIcon"

    

);
