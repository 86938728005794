import { Box, Checkbox, Link, Typography } from "@mui/material";
import * as React from "react";
import { Colors } from "../themes/Common";
import { ShowLearnMoreContext } from "../contexts";
import { CheckMarkIcon } from "../icons";

export interface PrivacyAgreementProps {
	agree?: boolean;
	onChange: (value: any) => void;
}

export const PrivacyAgreement: React.FunctionComponent<PrivacyAgreementProps> = (props) => {
	const { setShowLearnMore } = React.useContext(ShowLearnMoreContext);

	const handleParticipationAgreement = () => {
		setShowLearnMore(true);
	};

	return (
		<Box
			sx={{
				display: "flex",
				border: "1px solid" + Colors.white,
				borderRadius: "0.25rem",
				padding: "1.25rem",
			}}
		>
			<Checkbox
				sx={{ marginRight: "1.5rem" }}
				color="secondary"
				onChange={props.onChange}
				checkedIcon={<CheckMarkIcon />}
			/>
			<Typography>
				By checking this box, I confirm the accuracy of my information and accept the{" "}
				<Link
					sx={{ cursor: "pointer", fontSize: "inherit !important", color: Colors.kansasSkyBlue }}
					onClick={() => handleParticipationAgreement()}
				>
					Participant Agreement
				</Link>
				.
			</Typography>
		</Box>
	);
};

PrivacyAgreement.defaultProps = {
	agree: false,
};
