import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
        maxHeaderWidth: string;
        maxContentWidth: string;
        minContentMargin: string;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        maxHeaderWidth: string;
        maxContentWidth?: string;
        minContentMargin?: string;
    }
}
export const Colors = {
    blue3: "#0162A6",
    blue2: "#1A78BB",
    blue1: "#ADD0EA",
    gray4: "#64796D",
    gray3: "#C9CED1",
    gray2: "#F0F0F0",
    gray1: "#F9F9F9",
    // green: "#81A53E",
    green: "#95C93D",
    red: "#DF5252",
    pilotBlue: "#26343D",
    pilotBrown: "#8F8153",
    pilotYellow: "#FFE560",
    pilotGreen: "#7FDF52",
    white: "#FFFFFF",

    //new colors
    bgBlue: "#002856",
    lightBlue: "#BDE2F2",
    kansasSkyBlue: "#71C5E8",
    actionGreen: "#95C93D",
    actionBlue: "#0162A6", 
    textPrimary: "#002856",
    textSecondary: "#6D6E71",
    kansasGold: "#FCB31C"
}

export const setCommon = (textColor: string, themeOptions : ThemeOptions) => {
    let theme = createTheme({
        typography: {
            fontFamily: 'Poppins',
            body1: {
                color: textColor
            },
            body2: {
                color: textColor
            },
            h1: {
                color: textColor
            },
            h2: {
                color: textColor
            },
            h3: {
                color: textColor
            },
            h4: {
                color: textColor
            },
            h5: {
                color: textColor
            },
            h6: {
                color: textColor
            },
            subtitle1: {
                color: textColor
            },
            subtitle2: {
                color: textColor
            },
            caption: {
                color: textColor
            },
            overline: {
                color: textColor
            },
            button: {
                textTransform: "none"
            }
        },
        shape: {
            borderRadius: 2
        },
        maxContentWidth: '67.5rem',
        maxHeaderWidth: '74rem',
    });

    theme = createTheme(theme, {
        minContentMargin: '1.25rem',
        [theme.breakpoints.up('sm')]: {
            minContentMargin: '2.5rem'
        }
    });

    theme = createTheme(theme, themeOptions);

    return responsiveFontSizes(createTheme(theme, {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        padding: '0.75rem',
                        fontSize: '1.125rem',
                        fontStyle: 'normal',
                        lineHeight: '1.125rem',
                        textTransform: 'capitalize',
                        minWidth: 0,
                    },
                    containedPrimary: {
                        "&:hover": {
                            backgroundColor: theme.palette.primary.main
                        },
                        "&:active": {
                            backgroundColor: theme.palette.primary.main
                        },
                        "&:disabled": {
                            backgroundColor: theme.palette.action.disabled,
                            color: theme.palette.background.default
                        },
                    },
                    outlinedPrimary: {
                        "&:hover": {
                            backgroundColor: theme.palette.primary.light
                        },
                        "&:active": {
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText
                        },
                    },
                    textPrimary: {
                        "&:hover": {
                            backgroundColor: theme.palette.primary.light
                        },
                        "&:active": {
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText
                        },
                    },
                    sizeMedium: {
                        fontSize: 16,
                        minWidth: 120,
                        height: 40
                    }
                },
                defaultProps: {
                    disableRipple: true
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    colorPrimary: {
                        color: theme.palette.primary.main,
                    },
                    colorSecondary: {
                        color: textColor,
                        '&.Mui-checked': {
                            color: theme.palette.secondary.dark
                        },
                    },
                },
                defaultProps: {
                    disableRipple: true
                }
            },
            MuiLink: {
                defaultProps: {
                    variant: "h4",
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: "small",
                    variant: "outlined",
                    width: 258
                }
            },
            MuiToggleButton: {
                defaultProps: {
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        border: '1px solid ' + theme.palette.primary.dark,
                        borderRadius: 4,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.12)',
                        borderColor: theme.palette.primary.main,
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.light,
                            borderColor: theme.palette.primary.dark,
                            borderWidth: '3px',
                            borderLeft: '3px solid ' + theme.palette.primary.dark + " !important",
                        },
                        "&:hover": {
                            backgroundColor: Colors.gray2
                        },
                        "&:disabled": {
                            opacity: 0.65
                        }
                    }
                }
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        alignItems: "stretch",
                        "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                            marginLeft: 0,
                            borderLeft: '1px solid', 
                            borderTopLeftRadius: 4,
                            borderBottomLeftRadius: 4
                        },
                        "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4
                        },
                        "& .MuiToggleButtonGroup-grouped": {
                            width: '16.125rem',
                            padding: "1.25rem"
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: textColor,
                        "& fieldset": {
                            borderColor: Colors.actionBlue
                        }
                    },
                    input: {
                        "&.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.action.disabled,
                        },
                        "&::placeholder": {
                            fontStyle: 'italic',
                        },
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: theme.palette.primary.main
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow: 'unset',
                        backgroundColor: 'unset'
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(90deg)'
                        },
                        "&.Mui-expanded": {
                            minHeight: 0
                        }
                    },
                    content: {
                        "&.Mui-expanded": {
                            margin: '12px 0'
                        }
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        color: theme.palette.primary.main,
                        fontWeight: 700
                    }
                }
            }
        }
    }));
}
