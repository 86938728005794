import * as React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme, useTheme } from '@mui/material';

interface ContentMarginBoxProps {
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
    isHeader?: boolean; // Example of the first custom prop
  }

export const ContentMarginBox: React.FunctionComponent<ContentMarginBoxProps> = (props) => {
    const theme = useTheme();
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection:'row',
            ...props.sx
        }}>
            <Box minWidth={theme.minContentMargin}/>
            <Box flexGrow={1} sx={{maxWidth: props.isHeader ? theme.maxHeaderWidth : theme.maxContentWidth, marginX: 'auto'}}>
                {props.children}
            </Box>
            <Box minWidth={theme.minContentMargin}/>
        </Box>
    );
}
