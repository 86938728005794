import {
	Box,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _ from "lodash";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import X2JS from "x2js";
import { UserContext } from "../contexts";
import { SimSteps, CountiesByState } from "../models";
import { StepWizardPageProps } from "./BasePage";
import BaseInputPage from "./BaseInputPage";
import { Colors } from "../themes/Common";

interface IMenuItem {
	value: string;
	text: string;
}

const none = "!";
const xmlParser = new X2JS();

export const VehicleInfoIpsosPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user } = React.useContext(UserContext);
	const [states, setStates] = React.useState<IMenuItem[]>([]);
	const [counties, setCounties] = React.useState<IMenuItem[]>([]);
	const [vehyears, setVehyears] = React.useState<IMenuItem[]>([]);
	const [vehmakes, setVehmakes] = React.useState<IMenuItem[]>([]);
	const [vehmodels, setVehmodels] = React.useState<IMenuItem[]>([]);
	const [vehtrims, setVehtrims] = React.useState<IMenuItem[]>([]);
	const [state, setState] = React.useState(none);
	const [county, setCounty] = React.useState(none);
	const [vehyear, setVehyear] = React.useState(none);
	const [vehmake, setVehmake] = React.useState(none);
	const [vehmodel, setVehmodel] = React.useState(none);
	const [vehtrim, setVehtrim] = React.useState(none);
	const [vehcustom, setVehcustom] = React.useState("");
	const [mpgEst, setMpgEst] = React.useState<number | "">("");
	const [mpg, setMpg] = React.useState<number | "">("");
	const stepWizardProps = props as unknown as StepWizardChildProps;
	const [showCustomDesc, setShowCustomDesc] = React.useState(false);
	const [vehelectric, setvehelectric] = React.useState(false);
	const [vehAtvType, setVehAtvType] = React.useState("");

	React.useEffect(() => {
		if (user && user.state) {
			setState(user.state);
			setCounties(CountiesByState[user.state]);
		}
	}, [user, user?.state]);

	React.useEffect(() => {
		if (user && user.county) {
			setCounty(user?.county || none);

			if (!vehyears || vehyears.length === 0) {
				getVehYears();
			}
		}
		// eslint-disable-next-line
	}, [user, user?.county]);

	React.useEffect(() => {
		if (user) {
			let year = none;
			let make = none;
			let model = none;
			let trim = none;

			if (user.vehyear) {
				year = user.vehyear.toString();
				setVehyear(year);
				if (!vehmakes || vehmakes.length === 0) {
					getVehMakes(year);
				}
			}
			if (user.vehmake) {
				make = user.vehmake;
				setVehmake(make);
				if (!vehmodels || vehmodels.length === 0) {
					getVehModels(year, make);
				}
			}
			if (user.vehmodel) {
				model = user.vehmodel;
				setVehmodel(model);
				if (!vehtrims || vehtrims.length === 0) {
					getVehTrims(year, make, user.vehmodel);
				}
			}
			if (user.vehtrim) {
				trim = user.vehtrim;
				setVehtrim(user.vehtrim);
			}

			setVehAtvType(user.veh_epa_atvtype || "");
		}
		// eslint-disable-next-line
	}, [user, user?.vehyear, user?.vehmake, user?.vehmodel, user?.vehtrim, user?.veh_epa_atvtype]);

	React.useEffect(() => {
		if (user && user.ipsos_id) {
			if (user?.mpg) {
				setMpg(user?.mpg);
			}
			if (user?.mpg_epa) {
				setMpgEst(user?.mpg_epa);
			}
		}
	}, [user, user?.mpg, user?.mpg_epa]);

	React.useEffect(() => {
		if (!states || states.length === 0) {
			setStates([
				{ value: "KS", text: "Kansas" },
				{ value: "MN", text: "Minnesota" },
			]);
		}
	}, [states]);

	const handleStateChange = (event: SelectChangeEvent) => {
		const newState = event.target.value as string;
		console.log("update state to ", newState);
		setState(newState);
		setCounty(none);
		setCounties(CountiesByState[newState]);
	};

	const handleCountyChange = (event: SelectChangeEvent) => {
		const newCounty = event.target.value as string;
		console.log("updated county");

		setCounty(newCounty);

		if (!vehyears || vehyears.length === 0) {
			getVehYears();
		}
	};

	const getVehYears = () => {
		fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/year")
			.then((response) => {
				return response.text();
			})
			.then((responseText) => {
				const json = xmlParser.xml2js(responseText) as any;
				setVehyears(json.menuItems.menuItem);
			});
	};

	const handleVehYearChange = (event: SelectChangeEvent) => {
		const newYear = event.target.value as string;
		setVehyear(newYear);
		setVehmake(none);
		setVehmakes([]);
		setVehmodel(none);
		setVehmodels([]);
		setVehtrim(none);
		setVehtrims([]);
		setMpg("");
		setMpgEst("");

		getVehMakes(newYear);
	};

	const getVehMakes = (year: string) => {
		fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/make?year=" + year)
			.then((response) => {
				return response.text();
			})
			.then((responseText) => {
				const json = xmlParser.xml2js(responseText) as any;
				console.log(json);
				setVehmakes(_.isArray(json.menuItems.menuItem) ? json.menuItems.menuItem : [json.menuItems.menuItem]);
			});
	};

	const handleVehMakeChange = (event: SelectChangeEvent) => {
		const newMake = event.target.value as string;
		setVehmake(newMake);
		setVehmodels([]);
		setVehmodel(none);
		setVehtrim(none);
		setVehtrims([]);
		setMpg("");
		setMpgEst("");

		getVehModels(vehyear, newMake);
	};

	const getVehModels = (year: string, make: string) => {
		fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/model?year=" + year + "&make=" + make)
			.then((response) => {
				return response.text();
			})
			.then((responseText) => {
				const json = xmlParser.xml2js(responseText) as any;
				console.log(json);
				setVehmodels(_.isArray(json.menuItems.menuItem) ? json.menuItems.menuItem : [json.menuItems.menuItem]);
			});
	};

	const handleVehModelChange = (event: SelectChangeEvent) => {
		const newModel = event.target.value as string;
		setVehmodel(newModel);
		setVehtrim(none);
		setVehtrims([]);
		setMpg("");
		setMpgEst("");

		getVehTrims(vehyear, vehmake, newModel);
	};

	const getVehTrims = (year: string, make: string, model: string) => {
		fetch(
			"https://www.fueleconomy.gov/ws/rest/vehicle/menu/options?year=" +
				year +
				"&make=" +
				make +
				"&model=" +
				model
		)
			.then((response) => {
				return response.text();
			})
			.then((responseText) => {
				const json = xmlParser.xml2js(responseText) as any;
				console.log(json);
				setVehtrims(_.isArray(json.menuItems.menuItem) ? json.menuItems.menuItem : [json.menuItems.menuItem]);
			});
	};

	const handleVehTrimChange = (event: SelectChangeEvent, child: React.ReactNode) => {
		const newTrimKey = (child as any).key.substring(2);
		const newTrim = event.target.value as string;
		setVehtrim(newTrim);
		setMpg("");
		setMpgEst("");

		fetch("https://www.fueleconomy.gov/ws/rest/vehicle/" + newTrimKey)
			.then((response) => {
				return response.text();
			})
			.then((responseText) => {
				const json = xmlParser.xml2js(responseText) as any;
				console.log(json);
				const mpg = parseFloat(json.vehicle.comb08);
				setMpg(mpg);
				setMpgEst(mpg);
				setVehAtvType(json.vehicle.atvType ?? "");
			});
	};

	const handleVehcustomChange = (value: string) => {
		console.log(value);
		setVehcustom(value);
	};

	const handleMpgChange = (value: string) => {
		if (Number(value)) {
			console.log(value);
			const newMpg = parseInt(value);
			setMpg(newMpg);
		} else {
			setMpg("");
		}
	};

	const handleYesClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
		setvehelectric(value);
	};

	return (
		<BaseInputPage
			stepWizardProps={stepWizardProps}
			showLogo={true}
			showBackButton={false}
			canNavigateForward={
				state !== none &&
				county !== none &&
				((showCustomDesc && !!vehcustom && (!!mpg || vehelectric)) ||
					(!showCustomDesc && !!vehtrim && (!!mpg || vehAtvType === "EV")))
			}
			userUpdates={{
				vehyear: showCustomDesc ? undefined : parseInt(vehyear),
				vehmake: showCustomDesc ? undefined : vehmake,
				vehmodel: showCustomDesc ? undefined : vehmodel,
				vehtrim: showCustomDesc ? undefined : vehtrim,
				vehcustom: showCustomDesc ? vehcustom : undefined,
				mpg: mpg || undefined,
				mpg_epa: mpgEst || undefined,
				veh_override: mpg !== mpgEst,
				state: state,
				county: county,
				veh_epa_atvtype: showCustomDesc ? (vehelectric ? "EV" : undefined) : vehAtvType,
			}}
			title={"Tell us about your vehicle."}
		>
			<Box sx={{ marginTop: "4rem" }}>
				<Grid container xs={12} md={6} columnSpacing={1} sx={{ marginBottom: "2.25rem" }}>
					<Grid xs={12} md={6}>
						<Box sx={{ marginBottom: "0.75rem" }}>
							<Typography variant="caption">State of Registration</Typography>
						</Box>
						<Select
							sx={{ width: "100%" }}
							variant="outlined"
							value={state}
							onChange={handleStateChange}
							disabled={states.length === 0}
							displayEmpty
							renderValue={
								!!state && state !== none
									? undefined
									: () => (
											<div style={{ color: Colors.textSecondary }}>
												<em>Select</em>
											</div>
									  )
							}
						>
							{[
								<MenuItem disabled key={none} value={none}>
									<em>Select</em>
								</MenuItem>,
							].concat(
								states.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))
							)}
						</Select>
					</Grid>
					<Grid xs={12} md={6}>
						<Box sx={{ marginBottom: "0.75rem" }}>
							<Typography variant="caption">County</Typography>
						</Box>
						<Select
							sx={{ width: "100%" }}
							variant="outlined"
							value={county}
							onChange={handleCountyChange}
							disabled={counties.length === 0}
							displayEmpty
							renderValue={
								!!county && county !== none
									? undefined
									: () => (
											<div style={{ color: Colors.textSecondary }}>
												<em>Select</em>
											</div>
									  )
							}
						>
							{[
								<MenuItem disabled key={none} value={none}>
									<em>Select</em>
								</MenuItem>,
							].concat(
								counties.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))
							)}
						</Select>
					</Grid>
				</Grid>
				{!showCustomDesc && (
					<Grid xs={12} md={6} container columnSpacing={1} sx={{ marginBottom: "2.25rem" }}>
						<Grid xs={12} md={6}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Vehicle Year</Typography>
							</Box>
							<Select
								sx={{ width: "100%" }}
								variant="outlined"
								value={vehyear}
								onChange={handleVehYearChange}
								disabled={vehyears.length === 0}
								displayEmpty
								renderValue={
									!!vehyear && vehyear !== none
										? undefined
										: () => (
												<div style={{ color: Colors.textSecondary }}>
													<em>Select</em>
												</div>
										  )
								}
							>
								{[
									<MenuItem disabled key={none} value={none}>
										<em>Select</em>
									</MenuItem>,
								].concat(
									vehyears.map((item) => (
										<MenuItem key={item.value} value={item.value}>
											{item.text}
										</MenuItem>
									))
								)}
							</Select>
						</Grid>
						<Grid xs={12} md={6}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Vehicle Make</Typography>
							</Box>
							<Select
								sx={{ width: "100%" }}
								variant="outlined"
								value={vehmake}
								placeholder="Choose"
								onChange={handleVehMakeChange}
								disabled={vehmakes.length === 0}
								displayEmpty
								renderValue={
									!!vehmake && vehmake !== none
										? undefined
										: () => (
												<div style={{ color: Colors.textSecondary }}>
													<em>Select</em>
												</div>
										  )
								}
							>
								{[
									<MenuItem disabled key={none} value={none}>
										<em>Select</em>
									</MenuItem>,
								].concat(
									vehmakes.map((item) => (
										<MenuItem key={item.value} value={item.value}>
											{item.text}
										</MenuItem>
									))
								)}
							</Select>
						</Grid>
					</Grid>
				)}
				{!showCustomDesc && (
					<Grid xs={12} md={6} container columnSpacing={1} sx={{ marginBottom: "2.25rem" }}>
						<Grid xs={12} md={6}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Vehicle Model</Typography>
							</Box>
							<Select
								sx={{ width: "100%" }}
								variant="outlined"
								value={vehmodel}
								placeholder="Choose"
								onChange={handleVehModelChange}
								disabled={vehmodels.length === 0}
								displayEmpty
								renderValue={
									!!vehmodel && vehmodel !== none
										? undefined
										: () => (
												<div style={{ color: Colors.textSecondary }}>
													<em>Select</em>
												</div>
										  )
								}
							>
								{[
									<MenuItem disabled key={none} value={none}>
										<em>Select</em>
									</MenuItem>,
								].concat(
									vehmodels.map((item) => (
										<MenuItem key={item.value} value={item.value}>
											{item.text}
										</MenuItem>
									))
								)}
							</Select>
						</Grid>
						<Grid xs={12} md={6}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Vehicle Trim</Typography>
							</Box>
							<Select
								sx={{ width: "100%" }}
								variant="outlined"
								value={vehtrim}
								placeholder="Choose"
								onChange={handleVehTrimChange}
								disabled={vehtrims.length === 0}
								displayEmpty
								renderValue={
									!!vehtrim && vehtrim !== none
										? undefined
										: () => (
												<div style={{ color: Colors.textSecondary }}>
													<em>Select</em>
												</div>
										  )
								}
							>
								{[
									<MenuItem disabled key={none} value={none}>
										<em>Select</em>
									</MenuItem>,
								].concat(
									vehtrims.map((item) => (
										<MenuItem key={item.value} value={item.text}>
											{item.text}
										</MenuItem>
									))
								)}
							</Select>
						</Grid>
					</Grid>
				)}
				{!showCustomDesc && vehyears.length > 0 && (
					<Grid xs={12} md={6} container rowSpacing={2} columnSpacing={1} marginBottom={"4rem"}>
						<Grid xs={12}>
							<Link sx={{ cursor: "pointer" }} onClick={() => setShowCustomDesc(true)}>
								Don't see your vehicle? Enter a vehicle of your choice
							</Link>
						</Grid>
					</Grid>
				)}
				{showCustomDesc && (
					<Grid xs={12} md={6} container columnSpacing={1}>
						<Grid xs={12} sx={{ marginBottom: "2.25rem" }}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Vehicle's information</Typography>
							</Box>
							<TextField
								sx={{ minWidth: "100%" }}
								variant="outlined"
								value={vehcustom}
								placeholder="Enter your vehicle make, model and year"
								onChange={(e) => handleVehcustomChange(e.target.value)}
							/>
						</Grid>
						<Grid xs={12} md={12} sx={{ marginBottom: "2.25rem" }}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Is it an electric vehicle?</Typography>
							</Box>
							<Box>
								<ToggleButtonGroup
									value={vehelectric}
									exclusive
									onChange={handleYesClick}
									aria-label="Electric vehicle?"
								>
									<ToggleButton
										value={true}
										selected={!!vehelectric}
										aria-label="Yes"
										sx={{
											width: "4.3125rem !important",
											height: "2.5rem",
											color: Colors.blue2,
											fontSize: "1rem",
											fontWeight: "500",
											marginRight: "1rem",
										}}
									>
										Yes
									</ToggleButton>
									<ToggleButton
										value={false}
										selected={!vehelectric}
										aria-label="No"
										sx={{
											width: "4.3125rem !important",
											height: "2.5rem",
											color: Colors.blue2,
											fontSize: "1rem",
											fontWeight: "500",
										}}
									>
										No
									</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						</Grid>
					</Grid>
				)}
				{((showCustomDesc && !vehelectric) ||
					(!showCustomDesc && !!vehtrim && vehtrim !== none && vehAtvType !== "EV")) && (
					<Grid xs={12} md={6} container columnSpacing={1} marginBottom="2.25rem">
						<Grid xs={8}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Miles Per Gallon (MPG)</Typography>
							</Box>
							{!!mpgEst && !showCustomDesc && (
								<Box sx={{ marginBottom: "0.75rem" }}>
									<Typography variant="h5">
										EPA estimate for your vehicle: {mpgEst ? mpgEst : "--"} MPG
									</Typography>
								</Box>
							)}
							<TextField
								sx={{ minWidth: "100%" }}
								variant="outlined"
								value={mpg}
								placeholder=""
								onChange={(e) => handleMpgChange(e.target.value)}
							/>
						</Grid>
					</Grid>
				)}
				{showCustomDesc && (
					<Grid xs={12} md={6} container columnSpacing={1}>
						<Grid xs={12}>
							<Link sx={{ cursor: "pointer" }} onClick={() => setShowCustomDesc(false)}>
								Find your vehicle.
							</Link>
						</Grid>
					</Grid>
				)}
			</Box>
		</BaseInputPage>
	);
};

VehicleInfoIpsosPage.defaultProps = {
	stepName: SimSteps.VehicleInfo.name,
};
