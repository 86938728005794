import { Box, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { StepWizardChildProps } from 'react-step-wizard';
import { UserContext } from '../contexts';
import { CountyMileage, SimSteps } from '../models';
import { BaseInputPage } from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const MileageReportingPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user } = React.useContext(UserContext);
	const [mi_est, setMi_est] = useState<number | ''>('');
	const [exempt_mi, setExempt_mi] = useState<number | ''>(0);

	const countyMileage = CountyMileage as any;

	const getAverageMileage = React.useCallback((): number => {
		if (user && user.state) {
			return countyMileage[user.state][user.county] || countyMileage[user.state]['STATEWIDE'];
		}
		return 0;
	}, [countyMileage, user]);

	React.useEffect(() => {
		setMi_est(user && user.mi_est ? user.mi_est : getAverageMileage());
		setExempt_mi(user && user.exempt_mi ? user.exempt_mi : 0);
	}, [user, getAverageMileage]);

	const hasCountyMileage = (): boolean => {
		return !!user && !!countyMileage[user.state][user.county];
	};

	const mi_estChanged = (newValue: string) => {
		const entry = parseInt(newValue);
		setMi_est(entry);
	};

	const exemptMilesChanged = (newValue: string) => {
		const entry = parseInt(newValue);
		setExempt_mi(Number.isNaN(entry) ? '' : entry);
	};

	return (
		<BaseInputPage
			stepWizardProps={props as unknown as StepWizardChildProps}
			canNavigateForward={!!mi_est && mi_est > 0 && exempt_mi !== '' && exempt_mi >= 0 && exempt_mi < mi_est}
			userUpdates={{
				mi_est: mi_est || undefined,
				exempt_mi: exempt_mi === '' ? 0 : exempt_mi,
			}}
			showTotals={true}
			title='Estimate your monthly mileage and related costs.'>
			<Grid container spacing={1} sx={{ marginTop: '0.75rem' }}>
				<Grid xs={12} md={7} sx={{ maxWidth: '35rem' }}>
					<Box sx={{ marginBottom: '4rem' }}>
						{user?.veh_is_hd ? (
							<Typography>
								Cost estimates for passenger vehicles are based on a road usage charge rate equivalent
								to the current motor fuel tax, which is{' '}
								{parseFloat(((user?.rucRate() ?? 0) * 100).toFixed(2))} cents per mile. Heavy-duty rates
								have not been established for this pilot.
							</Typography>
						) : (
							<Typography>
								Cost estimates are based on an initial road usage charge rate of{' '}
								{parseFloat(((user?.rucRate() ?? 0) * 100).toFixed(2))} cents per mile which provides
								state revenue equal to the current motor fuel tax.
							</Typography>
						)}
					</Box>
					<Box sx={{ marginBottom: '3rem' }}>
						<Typography variant='h2' sx={{ marginBottom: '1rem' }}>
							First, estimate your total monthly miles
						</Typography>
						<Typography sx={{ marginBottom: '1rem' }}>
							You can use your county's average monthly mileage as reference.
						</Typography>
						<Typography variant='caption'>Mileage Estimate</Typography>
						{hasCountyMileage() ? (
							<Typography variant='h5' sx={{ marginTop: '0.75rem' }}>
								County average: {getAverageMileage().toLocaleString()} miles
							</Typography>
						) : (
							<Typography variant='h5' sx={{ marginTop: '0.75rem' }}>
								State average: {getAverageMileage().toLocaleString()} miles
							</Typography>
						)}
						<NumberFormat
							sx={{ marginTop: 0.75 }}
							thousandSeparator=','
							customInput={TextField}
							onValueChange={(values) => mi_estChanged(values.value)}
							value={mi_est}
						/>
					</Box>
				</Grid>
				<Grid xs={12} md={7} sx={{ maxWidth: '35rem' }}>
					<Box sx={{ marginBottom: '4rem' }}>
						<Typography variant='h2' sx={{ marginBottom: '1rem', maxWidth: '32rem' }}>
							Then, estimate how many miles you drive on private or out-of-state roads each month
						</Typography>
						<Typography variant='caption'>Exempt Mileage Estimate*</Typography>
						<br />
						<NumberFormat
							sx={{ marginTop: 0.75 }}
							thousandSeparator=','
							customInput={TextField}
							onValueChange={(values) => exemptMilesChanged(values.value)}
							value={exempt_mi}
						/>
						<Typography className='hint' sx={{ marginTop: '1rem', color: 'rgba(0, 0, 0, 0.87)' }}>
							*Some mileage reporting options offered in the next step may allow you to exempt these
							miles.
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</BaseInputPage>
	);
};

MileageReportingPage.defaultProps = {
	stepName: SimSteps.MileageReporting.name,
};
