import { Box, Button, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { UserContext } from "../contexts";
import { CountyMileage, SimSteps } from "../models";
import * as Server from "../server";
import BaseInfoPage from "./BaseInfoPage";
import { StepWizardPageProps } from "./BasePage";
import { PrivacyAgreement } from "../components/PrivacyAgreement";
import { Checklist } from "../components/Checklist";
import { Colors } from "../themes/Common";
import ParticipantAgreement from "../components/ParticipantAgreement/ParticipantAgreement";

export const WelcomePage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { setUser } = React.useContext(UserContext);
	const [loggingIn, setLoggingIn] = React.useState(true);
	const [userFound, setUserFound] = React.useState<boolean | null>(null);
	const [redirect_url, setRedirect_url] = React.useState<string | undefined>(undefined);
	const stepWizardProps = props as unknown as StepWizardChildProps;
	const [participationAgreement, setParticipationAgreement] = React.useState(false);
	const isFirstRender = React.useRef(true);

	React.useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			login();
		}
	});

	const login = async () => {
		setLoggingIn(true);
		try {
			console.log("Logging in...");
			const { id, ipsos_id, state, redirect_url } = getUrlParams();
			let userResult = null;
			if (id) {
				userResult = await Server.findUser(id);
			} else if (ipsos_id && state && (CountyMileage as any)[state]) {
				userResult = await Server.findIpsosUser(ipsos_id, state);
			}

			setUser(userResult);
			setUserFound(!!userResult);
			setRedirect_url(redirect_url);

			if (!userResult) {
				throw new Error("Login Failure: Invalid Credentials");
			}

			if (userResult.sim_status) {
				stepWizardProps.goToNamedStep(userResult.sim_status);
			}
		} catch (e) {
			console.error(e);
		}
		setLoggingIn(false);
	};

	const getUrlParams = () => {
		const params = new URLSearchParams(window.location.search);
		const newParams = {} as any;
		params.forEach((value, key) => {
			newParams[key.toLowerCase()] = value;
		});
		return newParams;
	};

	const handlePilotFAQ = () => {
		console.log("clicked Pilot FAQs");
	};

	const handleGetStarted = () => {
		if (participationAgreement && userFound) {
			props.nextPage();
		}
	};

	const steps = [
		"Tell us about your vehicle",
		"Estimate your miles and related costs",
		"Choose a mileage reporting method",
		"Review and confirm your information",
	];

	return (
		<BaseInfoPage
			stepWizardProps={stepWizardProps}
			useDarkTheme={true}
			showLogo={true}
			userUpdates={{
				redirect_url,
				terms_accepted: participationAgreement,
			}}
			learnMoreContent={
				<Grid container columnSpacing={1}>
					<ParticipantAgreement />

					<Grid sx={{ margin: "3em 0" }} xs={12}>
						<Typography sx={{ fontWeight: 600 }}>
							<a
								href={require("../components/ParticipantAgreement/KDOT_RUC_Pilot_Agreement.pdf")}
								style={{
									border: "1px solid " + Colors.actionBlue,
									borderRadius: "4px",
									cursor: "pointer",
									fontSize: "inherit !important",
									textDecoration: "none",
									padding: "0.5rem",
									color: Colors.actionBlue,
								}}
								download={"../components/ParticipantAgreement/KDOT_RUC_Pilot_Agreement.pdf"}
							>
								Download
							</a>
						</Typography>
					</Grid>
				</Grid>
			}
		>
			{!loggingIn && userFound && (
				<Box>
					<Box sx={{ paddingBottom: "4rem" }}>
						<Grid container columnSpacing={1}>
							<Grid xs={12} sx={{ marginBottom: "2rem" }}>
								<Typography variant="h2">Welcome</Typography>
							</Grid>
							<Grid xs={12} sx={{ marginBottom: "2rem" }}>
								<Box sx={{ display: "flex" }}>
									<Typography variant="h1">RUC Pilot Enrollment</Typography>
								</Box>
							</Grid>
							<Grid xs={12} sx={{ marginBottom: "2rem" }}>
								<Typography sx={{ fontWeight: "500", fontSize: "1.25rem" }}>
									Enroll your vehicle in four simple steps. You can see how your miles driven impact
									road usage cost estimates along the way.
									<br />
								</Typography>
							</Grid>
							<Grid xs={12} sx={{ marginBottom: "4rem" }}>
								<Typography>
									Have your vehicle identification number (VIN) and vehicle odometer mileage on hand
									for the best enrollment experience. For more information, see the Pilot FAQs.
								</Typography>
							</Grid>
							<Grid xs={12}>
								<Typography>
									<Checklist items={steps} />
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ paddingBottom: "4rem" }}>
						<PrivacyAgreement
							onChange={(event) => setParticipationAgreement(event.target.checked)}
						></PrivacyAgreement>
					</Box>

					<Box sx={{ paddingBottom: "1rem" }}>
						<Grid container rowSpacing={2} columnSpacing={1}>
							<Grid xs={12}>
								<Button
									sx={{ float: "left" }}
									variant="contained"
									onClick={handleGetStarted}
									disabled={!participationAgreement}
								>
									Get Started
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
			{!loggingIn && !userFound && (
				<Box>
					<Box sx={{ paddingBottom: "4rem" }}>
						<Grid container rowSpacing={2} columnSpacing={1}>
							<Grid xs={12}>
								<Typography variant="h2">Welcome</Typography>
							</Grid>
							<Grid xs={12}>
								<Box sx={{ display: "flex" }}>
									<Typography variant="h1">RUC Pilot Enrollment</Typography>
								</Box>
							</Grid>
							<Grid xs={12}>
								<Typography>
									<Box sx={{ fontWeight: "500", fontSize: "1.25rem" }}>
										We could not find your user record, please double check the url that was sent to
										you and try again.
									</Box>
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
			{loggingIn && (
				<Box>
					<Box sx={{ paddingBottom: "4rem" }}>
						<Grid container rowSpacing={2} columnSpacing={1}>
							<Grid xs={12}>
								<Typography variant="h2">Welcome</Typography>
							</Grid>
							<Grid xs={12}>
								<Box sx={{ display: "flex" }}>
									<Typography variant="h1">RUC Pilot Enrollment</Typography>
								</Box>
							</Grid>
							<Grid xs={12}>
								<Typography>
									<Box sx={{ fontWeight: "500", fontSize: "1.25rem" }}>
										Looking up your user record...
									</Box>
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
		</BaseInfoPage>
	);
};

WelcomePage.defaultProps = {
	stepName: SimSteps.Welcome.name,
};
