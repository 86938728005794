import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Colors } from "../themes/Common";
import { UserContext } from "../contexts";

export interface TotalsEstimatetProps {
	miles?: number;
	exemptMiles?: number;
	includeExemptMiles?: boolean;
}

const headerTextStyles = {
	color: Colors.textPrimary,
	fontSize: "1rem",
	fontWeight: 600,
};

const contentTextStyles = {
	color: Colors.textPrimary,
	fontSize: "2rem",
	fontStyle: "normal",
	fontWeight: 700,
};

const exemptTextStyles = {
	color: Colors.textPrimary,
	fontSize: "0.875rem",
	fontStyle: "normal",
	fontWeight: 400,
};

const dollarSybmbolStyles = {
	fontSize: "1rem",
	fontStyle: "normal",
	fontWeight: "700",
	verticalAlign: "super",
};

const mileSybmbolStyles = {
	fontSize: "1rem",
	fontStyle: "normal",
	fontWeight: "700",
};

// Use as example of a reusable component
export const TotalsEstimate: React.FunctionComponent<TotalsEstimatetProps> = (props) => {
	const { user, updateUser } = React.useContext(UserContext);
	const getMiles = React.useCallback(() => {
		if (!props.miles) {
			return 0;
		}

		if (props.exemptMiles && props.includeExemptMiles) {
			return Math.max(0, props.miles - props.exemptMiles);
		}

		return props.miles;
	}, [props.miles, props.exemptMiles, props.includeExemptMiles]);

	return (
		<Box
			sx={{
				border: 1,
				borderColor: Colors.kansasGold,
				borderRadius: "2px",
				width: "20.5rem",
				display: { xs: "flex", lg: "block" },
				flexDirection: "column",
			}}
		>
			<Box sx={{ padding: "6px 12px", backgroundColor: Colors.kansasGold }}>
				<Typography variant="h3" style={headerTextStyles}>
					Monthly Estimated Totals
				</Typography>
			</Box>
			<Box
				sx={{
					padding: "1rem",
					paddingBottom: "0.75rem",
					backgroundColor: "rgba(252, 179, 28, 0.05)",
					display: "flex",
				}}
			>
				<Box sx={{ paddingRight: "1rem", borderRight: "1px solid " + Colors.kansasGold }}>
					<Typography style={contentTextStyles}>
						<span style={dollarSybmbolStyles}>$</span>
						{(getMiles() * (user?.rucRate() ?? 0)).toFixed(2)}
					</Typography>
				</Box>
				<Box sx={{ paddingLeft: "1rem" }}>
					<Typography style={contentTextStyles}>
						{getMiles()}
						<span style={mileSybmbolStyles}> mi</span>
					</Typography>
				</Box>
			</Box>
			{!!props.exemptMiles && (
				<Box sx={{ padding: "1rem", paddingTop: 0, backgroundColor: "rgba(252, 179, 28, 0.05)" }}>
					{!props.includeExemptMiles ? (
						<Typography style={exemptTextStyles}>
							<span style={{ color: Colors.red, marginRight: ".5em" }}>✘</span>Does not include mileage
							exemption
						</Typography>
					) : (
						<Typography style={exemptTextStyles}>
							<span style={{ color: Colors.pilotGreen, marginRight: ".5em" }}>✔</span>Includes exemption
							for {props.exemptMiles} miles
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
};
