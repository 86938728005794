import { SvgIcon } from '@mui/material';
import React from 'react';

interface ExternalLinkIconProps {
    color?: string;
}

export const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = ({ color = "#6D6E71", ...props }) => {
    return (
    <SvgIcon {...props}>
        <path d="M14.25 3C13.6277 3 13.125 3.50273 13.125 4.125C13.125 4.74727 13.6277 5.25 14.25 5.25H17.1574L10.0805 12.3305C9.64102 12.7699 9.64102 13.4836 10.0805 13.923C10.5199 14.3625 11.2336 14.3625 11.673 13.923L18.75 6.84258V9.75C18.75 10.3723 19.2527 10.875 19.875 10.875C20.4973 10.875 21 10.3723 21 9.75V4.125C21 3.50273 20.4973 3 19.875 3H14.25ZM5.8125 4.125C4.25859 4.125 3 5.38359 3 6.9375V18.1875C3 19.7414 4.25859 21 5.8125 21H17.0625C18.6164 21 19.875 19.7414 19.875 18.1875V14.25C19.875 13.6277 19.3723 13.125 18.75 13.125C18.1277 13.125 17.625 13.6277 17.625 14.25V18.1875C17.625 18.4969 17.3719 18.75 17.0625 18.75H5.8125C5.50312 18.75 5.25 18.4969 5.25 18.1875V6.9375C5.25 6.62812 5.50312 6.375 5.8125 6.375H9.75C10.3723 6.375 10.875 5.87227 10.875 5.25C10.875 4.62773 10.3723 4.125 9.75 4.125H5.8125Z" fill={color}/>
    </SvgIcon>
    );

};
