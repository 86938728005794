import { createSvgIcon } from '@mui/material';

export const InfoIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path d="M9.00224 4.56689C9.25824 4.56689 9.46624 4.65089 9.62624 4.81889C9.79424 4.97889 9.87824 5.17889 9.87824 5.4189C9.87824 5.65889 9.79424 5.85889 9.62624 6.0189C9.46624 6.17889 9.25824 6.25889 9.00224 6.25889C8.73824 6.25889 8.52624 6.17889 8.36624 6.0189C8.20624 5.85889 8.12624 5.65889 8.12624 5.4189C8.12624 5.17089 8.20624 4.96689 8.36624 4.8069C8.53424 4.64689 8.74624 4.56689 9.00224 4.56689ZM9.92624 13.0269H11.6542V14.0109H6.69824V13.0269H8.58224V8.6589H6.75824V7.6749H9.92624V13.0269Z" fill="#0162A6" />
        <circle cx="9" cy="9.86719" r="8" stroke="#0162A6" stroke-width="2" />
    </svg>
    ,
    "InfoIcon"

    

);
