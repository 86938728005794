import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { UserContext } from "../contexts";
import { Compensation, Followup, ReportingOptions, SimSteps, SurveyUrl } from "../models";
import { StepWizardPageProps } from "./BasePage";
import BaseInfoPage from "./BaseInfoPage";
import { ExclaimationIcon } from "../icons";
const urlParams = new URLSearchParams(window.location.search);
const redirect_url = urlParams.get("redirect_url");
export const ConfirmationPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user } = React.useContext(UserContext);
	const appInsights = useAppInsightsContext();

	// const handleContinueClicked = () => {
	// 	appInsights.trackEvent({ name: "FinishClicked" }, { page: props.stepName });
	// 	if (user?.ipsos_id) {
	// 		if (user?.redirect_url) {
	// 			window.open(user.redirect_url + "&ext_st=1&intlen=10&termpoint8=");
	// 		}
	// 	} else {
	// 		if (user?.mro_selection === ReportingOptions.Manual.value) {
	// 			window.open(dec);
	// 		} else {
	// 			window.open(SurveyUrl.Other);
	// 		}
	// 	}
	// };

	return (
		<BaseInfoPage
			stepWizardProps={props as unknown as StepWizardChildProps}
			useDarkTheme={true}
			showLogo={true}
			userUpdates={{}}
		>
			<Box sx={{ paddingBottom: "2rem" }}>
				<Grid container columnSpacing={1}>
					<Grid xs={12}>
						<Box sx={{ display: "flex" }}>
							<Typography variant="h1">Almost finished!</Typography>
						</Box>
					</Grid>
					{user?.ipsos_id && (
						<Grid xs={12}>
							<Typography sx={{ fontWeight: "500", fontSize: "1.25rem" }}>
								Thank you for enrolling your vehicle. Compare your potential future costs and share your
								thoughts on the experience.
							</Typography>
						</Grid>
					)}

					{!user?.ipsos_id && (
						<Grid xs={12}>
							<Typography sx={{ fontWeight: "500", fontSize: "1.25rem" }}>
								Thank you for enrolling your vehicle. You can expect an email from{" "}
								{user?.mro_selection === ReportingOptions.Manual.value
									? "the Kansas Midwest RUC Pilot prompting you to submit a final odometer reading at the end of the pilot in June."
									: "the pilot technology provider, GeoToll, with instructions to set up your mileage reporting method."}
							</Typography>
							<br />
							<Typography sx={{ fontWeight: "500", fontSize: "1.25rem" }}>
								{user?.state === "KS"
									? `In the meantime, share your thoughts and get a ${Compensation.KS} reward.`
									: "In the meantime, share your thoughts by responding to the survey."}
							</Typography>

							{user?.state === "KS" ? (
								<>
									<br />
									<div style={{ display: "flex", alignItems: "center" }}>
										<ExclaimationIcon />
										<Typography
											sx={{
												fontWeight: "400",
												fontSize: "1rem",
												marginLeft: "0.5rem",
												opacity: 0.8,
											}}
										>
											{" "}
											You need to complete the survey to receive the reward
										</Typography>
									</div>
								</>
							) : null}
						</Grid>
					)}
				</Grid>
			</Box>
			<Box sx={{ paddingBottom: "1rem" }}>
				<Grid container rowSpacing={2} columnSpacing={1}>
					<Grid xs={12}>
						<Button
							sx={{ float: "left" }}
							variant="contained"
							href={decodeURI(redirect_url ?? "https://google.com/")}
						>
							{user?.ipsos_id ? "Continue" : "Take Enrollment Survey"}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</BaseInfoPage>
	);
};

ConfirmationPage.defaultProps = {
	stepName: SimSteps.Confirmation.name,
};
