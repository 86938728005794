import { createSvgIcon } from '@mui/material';

export const CheckIcon = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10.7051" r="9" transform="rotate(-180 10 10.7051)" stroke="#95C93D" stroke-width="2"/>
        <path d="M5.85547 10.5951L8.80322 13.5434L14.3417 8.00488" stroke="#95C93D" stroke-width="2" stroke-linecap="round"/>
    </svg>
    ,
    "CheckIcon"

    

);
