import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createMemoryHistory } from "history";
const { NODE_ENV } = process.env;

const productionConnectionString = 'InstrumentationKey=1644c363-a8a4-44f8-a706-cfa263879af7;IngestionEndpoint=https://kdotrucappinsights.azurewebsites.net/';
 // Set this to something else if you don't want to log to the production application insights
const testConnectionString = productionConnectionString;

const browserHistory = createMemoryHistory();
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: NODE_ENV === 'production' ? productionConnectionString : testConnectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();