import { Box } from '@mui/material';
import * as React from 'react';
import { CheckIcon } from '../icons';

export interface ChecklistProps {
    items: string[]
}

export const Checklist: React.FunctionComponent<ChecklistProps> = (props) => {
    const listItems = props.items.map((text) =>
        <li key={text} style={{marginBottom: '0.5rem'}}>
            <Box sx={{display: 'flex'}}>
                <CheckIcon sx={{marginRight: '0.75rem'}}/>
                {text}
            </Box>
        </li>
    );

    return (
        <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
            {listItems}
        </ul>
    );
}
