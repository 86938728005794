import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

interface QuestionIconProps {
    color?: string;
}

export const QuestionIcon: React.FC<QuestionIconProps> = ({ color = "#BDE2F2", ...props }) => {
    return (
    <SvgIcon {...props} viewBox="0 0 27 27">
        <circle cx="13.4668" cy="13" r="12" fill="none" stroke={color} stroke-width="2"/>
        <path d="M13.4341 6.87402C14.2141 6.87402 14.8861 7.00602 15.4501 7.27002C16.0261 7.53402 16.4641 7.88802 16.7641 8.33202C17.0641 8.77602 17.2141 9.25002 17.2141 9.75402C17.2141 10.222 17.1301 10.624 16.9621 10.96C16.8061 11.284 16.6141 11.554 16.3861 11.77C16.1581 11.974 15.8521 12.214 15.4681 12.49C15.1201 12.742 14.8441 12.958 14.6401 13.138C14.4361 13.318 14.2621 13.54 14.1181 13.804C13.9861 14.068 13.9201 14.38 13.9201 14.74V15.262H11.9941V14.65C11.9941 14.134 12.0781 13.696 12.2461 13.336C12.4261 12.964 12.6361 12.664 12.8761 12.436C13.1281 12.196 13.4461 11.932 13.8301 11.644C14.2741 11.308 14.5981 11.026 14.8021 10.798C15.0061 10.558 15.1081 10.258 15.1081 9.89802C15.1081 9.46602 14.9341 9.13002 14.5861 8.89002C14.2381 8.63802 13.7881 8.51202 13.2361 8.51202C12.8041 8.51202 12.3721 8.61402 11.9401 8.81802C11.5201 9.02202 11.1421 9.33402 10.8061 9.75402L9.45605 8.71002C10.5121 7.48602 11.8381 6.87402 13.4341 6.87402ZM13.0021 17.062C13.3861 17.062 13.7101 17.194 13.9741 17.458C14.2381 17.722 14.3701 18.04 14.3701 18.412C14.3701 18.796 14.2381 19.12 13.9741 19.384C13.7101 19.648 13.3861 19.78 13.0021 19.78C12.6181 19.78 12.2941 19.648 12.0301 19.384C11.7781 19.12 11.6521 18.796 11.6521 18.412C11.6521 18.04 11.7841 17.722 12.0481 17.458C12.3121 17.194 12.6301 17.062 13.0021 17.062Z" fill={color}/>
    </SvgIcon>
    );

};
