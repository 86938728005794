import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { ShowLearnMoreContext, UserContext } from "../contexts";
import { ReportingOptions, RucRate, SimSteps, VehicleWeightsByState } from "../models";
import * as Server from "../server";
import { StepWizardPageProps } from "./BasePage";
import BaseInputPage from "./BaseInputPage";
import { Colors } from "../themes/Common";
import { ErrorIcon, InfoIcon } from "../icons";

const none = "!";

const lightVehInfoBoxStyles = {
	paddingLeft: "1rem",
	borderLeft: "1px solid " + Colors.textSecondary,
};

export const VehicleInfoVinPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user, updateUser } = React.useContext(UserContext);
	const [vehvin, setVehvin] = React.useState("");
	const [vehyear, setVehyear] = React.useState(none);
	const [vehmake, setVehmake] = React.useState(none);
	const [vehmodel, setVehmodel] = React.useState(none);
	const [vehTrim, setVehTrim] = React.useState(none);
	const [vehweight, setVehweight] = React.useState(none);
	const [mpgEst, setMpgEst] = React.useState<number | undefined>(undefined);
	const [mpg, setMpg] = React.useState<number | undefined>(undefined);
	const [fuelType, setFuelType] = React.useState(none);
	const stepWizardProps = props as unknown as StepWizardChildProps;
	const [showMpg, setShowMpg] = React.useState(false);
	const [showVehInfo, setShowVehInfo] = React.useState(false);
	const [showVehWeight, setShowVehWeight] = React.useState(false);
	const [invalidVin, setInvalidVin] = React.useState(false);
	const [vehAtvType, setVehAtvType] = React.useState(none);
	const [smartApp, setSmartApp] = React.useState(false);
	const [telematics, setTelematics] = React.useState(false);
	const [vinResponse, setVinResponse] = React.useState(none);
	const { setShowLearnMore } = React.useContext(ShowLearnMoreContext);

	React.useEffect(() => {
		setVehvin(user?.vin || "");
		setVehyear((user?.vehyear && user.vehyear.toString()) || "");
		setVehmake(user?.vehmake || "");
		setVehmodel(user?.vehmodel || "");
		setVehTrim(user?.vehtrim || "");
		setVehweight(user?.veh_weight || "");
		setMpgEst(user?.mpg_epa || undefined);
		setMpg(user?.mpg || undefined);

		if (user?.veh_is_hd) {
			setShowVehWeight(!!user?.vin);
			setShowMpg(!!user?.vin && !!user.veh_weight);
		} else {
			setShowVehInfo(!!user?.vin && !!user.vehmodel);
			//setShowMpg(!!user?.vin && !!user.vehmodel);
		}
	}, [user, user?.vin, user?.veh_weight, user?.vehyear, user?.vehmake, user?.vehmodel, user?.mpg]);

	React.useEffect(() => {
		if (vinResponse !== none) {
			if (vehvin.toLocaleUpperCase() === vinResponse) {
				setShowVehInfo(true);
			}
			setVinResponse(none);
		}
	}, [vinResponse, vehvin]);

	const handleMpgChange = (value: string) => {
		if (Number(value)) {
			const newMpg = parseInt(value);
			setMpg(newMpg);
		} else {
			setMpg(undefined);
		}
	};

	const handleVinChange = (value: string) => {
		setVehvin(value);
		value = value.toLocaleUpperCase();
		setShowVehInfo(false);
		if (validVin(value)) {
			setInvalidVin(false);
			enterVin(value);
		} else {
			setInvalidVin(true);
		}
	};

	const validVin = (vin: string) => {
		const vinRegex: RegExp = /^[A-HJ-NPR-Z0-9]{17}$/;
		return vinRegex.test(vin);
	};

	const enterVin = async (vin: string) => {
		let response = {
			data: {
				year: "",
				make: "",
				model: "",
				trim: "",
				mpg: null,
				fuel_type: "",
				eligibility: [{ eligible: false }],
				atvtype: "",
			},
		};
		response = await Server.getVehInfo(vin);
		setVehyear(response.data.year ?? "");
		setVehmake(response.data.make ?? "");
		setVehmodel(response.data.model ?? "");
		setVehTrim(response.data.trim ?? undefined);
		setMpg(response.data.mpg ?? undefined);
		setMpgEst(response.data.mpg ?? undefined);
		setFuelType(response.data.fuel_type ?? "");
		setSmartApp(getEligibility(response.data, ReportingOptions.Smartapp.value));
		setTelematics(getEligibility(response.data, ReportingOptions.Telematics.value));
		setVehAtvType(response.data.atvtype ?? "");

		if (user?.veh_is_hd) {
			setShowVehWeight(true);
		} else {
			setVinResponse(vin);
		}
	};

	const getEligibility = (responseData: any, mro_method: string) => {
		for (let i = 0; i < responseData.eligibility.length; i++) {
			const item = responseData.eligibility[i];
			if (item.type === mro_method) {
				return item.eligible;
			}
		}

		return false;
	};

	const handleVehWeightChange = (event: SelectChangeEvent, child: React.ReactNode) => {
		console.log(child);
		const newWeight = event.target.value as string;
		setVehweight(newWeight);
		setShowMpg(true);
	};

	const handleVinInfoClick = () => {
		setShowLearnMore(true);
	};

	const nextClicked = async (): Promise<boolean> => {
		// If the VIN changed we need to reset mro_selection, and set progress to this page
		if (user && !user.veh_is_hd && user.mro_selection && user.vin && user.vin !== vehvin) {
			console.log("Resetting MRO selection");
			await updateUser({
				id: user?.id,
				mro_selection: undefined,
				sim_step: stepWizardProps.currentStep + 1,
				sim_status: props.nextPageName(),
			});
		}

		return true;
	};

	return (
		<BaseInputPage
			stepWizardProps={stepWizardProps}
			showLogo={true}
			showBackButton={false}
			canNavigateForward={
				!!vehvin &&
				((!!user?.veh_is_hd && !!mpg && !!vehweight && vehweight !== none) || (!user?.veh_is_hd && showVehInfo))
			}
			acceptOnClick={nextClicked}
			userUpdates={{
				vehyear: parseInt(vehyear),
				vehmake: vehmake,
				vehmodel: vehmodel,
				vehtrim: vehTrim || undefined,
				veh_weight: vehweight || undefined,
				mpg: mpg,
				mpg_epa: mpgEst,
				veh_fueltype: fuelType,
				veh_override: mpg !== mpgEst,
				smart_app: smartApp,
				telematics: telematics,
				vin: vehvin && vehvin.toLocaleUpperCase(),
				veh_epa_atvtype: vehAtvType,
			}}
			title={"Tell us about your vehicle."}
			learnMoreContent={
				<Grid container spacing={1}>
					<Grid xs={12}>
						<Typography variant="h2">Where is my VIN?</Typography>
					</Grid>
					<Grid xs={12}>
						<Typography style={{ color: Colors.textPrimary }}>
							The VIN may also appear in a number of other locations:
						</Typography>
						<ul style={{ color: Colors.textPrimary, marginTop: "0" }}>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>Vehicle registration</Typography>
							</li>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>
									Inside the driver-side doorjamb. Open the door and look underneath where the
									side-view mirror would be located if the door was shut.
								</Typography>
							</li>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>
									Driver-side doorpost. Open the door and look near the spot where the door latches,
									not too far from the seatbelt return.Front of the engine block. This should be easy
									to spot by popping open the hood, and looking at the front of the engine.
								</Typography>
							</li>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>
									Front of the car frame, near the container that holds windshield washer fluid.
								</Typography>
							</li>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>
									Through the windshield on the driver's side of the vehicle.
								</Typography>
							</li>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>
									Rear wheel well. Try looking up, directly above the tire.
								</Typography>
							</li>
							<li>
								<Typography style={{ color: Colors.textPrimary }}>
									Underneath the spare tire.
								</Typography>
							</li>
						</ul>
						<Typography style={{ color: Colors.textPrimary }}>
							Your VIN is needed to determine your vehicle's compatibility with mileage reporting methods.
						</Typography>
					</Grid>
				</Grid>
			}
		>
			<Box sx={{ marginTop: "4rem" }}>
				<Grid container xs={12} md={6} sx={{ marginBottom: "4rem" }}>
					<Grid xs={12}>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="caption">Vehicle ID Number (VIN)</Typography>
							</Box>
							<InfoIcon onClick={handleVinInfoClick} sx={{ cursor: "pointer" }} />
						</Box>
						<TextField
							sx={{ minWidth: "100%" }}
							variant="outlined"
							value={vehvin}
							placeholder="Enter VIN"
							onChange={(e) => handleVinChange(e.target.value)}
							inputProps={{ style: { textTransform: "uppercase" } }}
						/>
						{invalidVin && (
							<Box sx={{ display: "flex", marginTop: "0.75rem" }}>
								<ErrorIcon sx={{ marginRight: "0.38rem" }} />
								<Typography variant="h5">Invalid VIN. Try Again</Typography>
							</Box>
						)}
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Box sx={{ marginTop: "0.75rem" }}>
								<Typography variant="h5">
									Please be aware that VINs don't contain the letters: "I", "O", and "Q".
								</Typography>
								<br />
								<Typography variant="h5">
									Your VIN is needed to determine your vehicle's compatibility with mileage reporting
									methods offered
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>
				{showVehInfo && (
					<Box>
						<Grid xs={12} md={6} container sx={{ marginBottom: "2rem" }}>
							<Grid xs={12} md={6} style={lightVehInfoBoxStyles}>
								<Typography variant="caption">Vehicle Year</Typography>
								<Typography variant="h4">{vehyear}</Typography>
							</Grid>
							<Grid xs={12} md={6} style={lightVehInfoBoxStyles}>
								<Typography variant="caption">Vehicle Make</Typography>
								<Typography variant="h4">{vehmake}</Typography>
							</Grid>
						</Grid>

						<Grid xs={12} md={6} container sx={{ marginBottom: "2rem" }}>
							<Grid xs={12} md={6} style={lightVehInfoBoxStyles}>
								<Typography variant="caption">Vehicle Model</Typography>
								<Typography variant="h4">{vehmodel}</Typography>
							</Grid>
							<Grid xs={12} md={6} style={lightVehInfoBoxStyles}>
								<Typography variant="caption">Miles Per Gallon (MPG)</Typography>
								<Typography variant="h4">{mpg ? mpg : "--"} mpg</Typography>
							</Grid>
						</Grid>
					</Box>
				)}
				{showVehWeight && (
					<Grid xs={12} md={6} container sx={{ marginBottom: "4rem" }}>
						<Box sx={{ marginBottom: "0.75rem" }}>
							<Typography variant="caption">Vehicle Weight</Typography>
						</Box>
						<Select
							sx={{ width: "100%" }}
							variant="outlined"
							value={vehweight}
							onChange={handleVehWeightChange}
							displayEmpty
							renderValue={
								!!vehweight && vehweight !== none
									? undefined
									: () => (
											<div style={{ color: Colors.textSecondary }}>
												<em>Select</em>
											</div>
									  )
							}
						>
							{[
								<MenuItem disabled key={none} value={none}>
									<em>Select</em>
								</MenuItem>,
							].concat(
								VehicleWeightsByState[user?.state || ""].map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))
							)}
						</Select>
					</Grid>
				)}
				{showMpg && (
					<Grid sx={{ display: "flex", flexDirection: "column" }} xs={12} md={6} container>
						<Box sx={{ marginBottom: "0.75rem" }}>
							<Typography variant="caption">Miles Per Gallon (MPG)</Typography>
						</Box>
						{!!mpgEst && (
							<Box sx={{ marginBottom: "0.75rem" }}>
								<Typography variant="h5">
									EPA estimate for your vehicle: {mpgEst ? mpgEst : "--"} MPG
								</Typography>
							</Box>
						)}
						<TextField
							sx={{ minWidth: "100%" }}
							variant="outlined"
							value={mpg ?? ""}
							placeholder="Enter number"
							onChange={(e) => handleMpgChange(e.target.value)}
						/>
					</Grid>
				)}
			</Box>
		</BaseInputPage>
	);
};

VehicleInfoVinPage.defaultProps = {
	stepName: SimSteps.VehicleInfo.name,
};
